@import 'assets/variables';
@import 'assets/mixins';

@mixin cao-table() {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  caption,
  table th {
    @include body--bold;

    text-align: left;
  }

  caption {
    margin-bottom: 1.2rem;
    color: $color--fuscous-gray;
  }

  table thead {
    display: table-header-group;
  }

  table thead th {
    padding: 2rem 1.2rem;
  }

  table tr:first-child>th,
  table tr:first-child>td {
    border-top: 0.1rem solid $color--mystic;
  }

  table tr:nth-child(even) {
    background-color: $color--black-squeeze;
  }

  table th,
  table td,
  table th>p,
  table td>p {
    color: $color--calypso;
    padding: 0.4rem 1.2rem;
    margin: 0;
    text-align: left;
  }

  table th {
    border-left: 0.1rem solid $color--mystic;
    border-right: 0.1rem solid $color--mystic;
    background-color: $color--mystic;
  }

  table td {
    @include body--regular;

    white-space: nowrap;
    border-right: 0.1rem solid $color--mystic;
    border-left: 0.1rem solid transparent;
  }

  table td:last-child {
    border-right-color: transparent;
  }
}

.NodeCao--Page {
  background-color: $color--white;

  &__inner {
    @media #{$tablet-only} {
      padding-top: 5rem;
    }

    .content-section {
      padding-bottom: 0;
    }
  }

  h1 {
    margin-bottom: 2rem;

    @media #{$normal} {
      margin-bottom: 4rem;
    }
  }

  &__hero {
    margin-bottom: 3rem;

    @media #{$tablet} {
      margin-bottom: 6rem;
    }

    &__image {
      margin-top: 3rem;

      @media #{$tablet} {
        margin-top: 4rem;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__pdf-download {
      margin-top: 3rem;

      @media #{$tablet} {
        margin-top: 4rem;
      }

      .Accordion__item__title button {
        @include body--bold;

        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        border-bottom: 0.2rem solid transparent;
        color: $color--fuscous-gray;
        background-color: $color--kournikova;

        .icon-caret {
          @include font-size(22);

          transform: rotate(-90deg);
          transition: transform 0.1s linear;
        }
      }

      .Accordion__item.collapsed {
        .Accordion__item__title button {
          border-color: $color--border;
          background-color: transparent;
        }

        .icon-caret {
          transform: rotate(90deg);
          transition: transform 0.1s linear;
        }
      }
    }
  }

  .ParagraphChapter {
    margin: 2rem 0;
  }

  .ParagraphChapterSection {
    margin: 2rem 0;

    p {
      margin-bottom: 2rem;
    }

    &__body {
      @include cao-table;

      margin-top: 2rem;

      div.table {
        overflow-x: scroll;
      }
    }
  }
}