@import 'assets/variables';
@import 'assets/mixins';

.Downloads {
  &__inner {
    margin-bottom: 3rem;
    min-height: 8rem;
    position: relative;

    h3 {
      margin-bottom: 1.5rem;
      @media #{$tablet} {
        margin-bottom: 3rem;
      }
    }

    @media #{$tablet} {
      margin-bottom: 6rem;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 1.5rem;
    @media #{$tablet} {
      margin-bottom: 2rem;
      font-size: 2.6rem;
    }
  }

  &__list {
    list-style: none;
    padding-left:0;
    margin: 0;
  }

  &__item {
    display: flex;
    padding-left: 0 !important;
    margin-bottom: 0 !important;

    &:not(:last-of-type) {
      padding-bottom: 1.5rem;
    }

    &:before {
      content: none !important;
    }

    &__content {
      a {
        @include font-size(16);
        @include font--secondary();
        line-height: 1.5;
        color: $color--jaffa;

        &:hover,
        &:focus {
          color: $color--jaffa;
        }
      }
    }

    &__icon {
      font-size: 2.4rem;
      padding-right: 1.8rem;
      @media #{$tablet} {
        font-size: 2.8rem;
      }
    }

    &__metadata {
      display: flex;
      flex-direction: row;

      font-size: 1.4rem;
      @media #{$tablet} {
        font-size: 1.6rem;
      }

      &__filesize {
        margin-left: 5px;

        &:before {
          content: '(';
          margin-left: 0.25em;
        }

        &:after {
          content: ')';
        }
      }
    }
  }
}
