@import 'assets/mixins';
@import 'assets/grid';

.container {
  &.container--small {
    // 77rem + 3rem gutter
    max-width: 80rem;
  }

  &.container--large {
    // 180rem + 5rem gutter
    max-width: 185rem;
  }
}

.container,
.container--fluid {
  // Enlarge side paddings to equal 1 gutter size.
  @include make-container;
}
