@import 'assets/variables';
@import 'assets/mixins';

.InterviewTeaserLarge {
  height: 100%;
  background-color: $color--black-squeeze;
  transition: background-color 0.33s linear;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }


  &__content {
    display: flex;
    flex-direction: column;

    @media #{$tablet} {
      display: flex;
      flex-direction: row;
    }

    .reversed & {
      @media #{$tablet} {
        flex-direction: row-reverse;
      }
    }
  }

  &__imageWrapper {
    width: 100%;
    height: auto;
    padding: 0 !important;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  div.InterviewTeaserLarge__contentWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 3rem 2rem;

    @media #{$tablet} {
      padding: 3rem;
    }

    @media #{$normal} {
      padding: 6rem;
    }
  }

  &__theme {
    display: flex;
    text-transform: uppercase;
    font-size: 1.2rem;
    justify-content: space-between;
    align-items: center;

    @media #{$tablet} {
      justify-content: unset;
    }
  }

  &__title {
    margin-top: 1rem;

    @media #{$normal} {
      margin-top: 1.5rem;
    }
  }

  &__body {
    margin-top: 1rem;

    @media #{$normal} {
      margin-top: 1.5rem;
    }

    span {
      @media #{$tablet} {
        font-size: 2rem;
      }
    }
  }

  &__date {
    margin-top: 1rem;

    @media #{$tablet} {
      display: none;
    }
  }

  &__author {
    display: flex;
    align-items: center;

    @media #{$mobile-only} {
      margin-top: 1rem;
    }

    @media #{$tablet} {
      margin-top: 1.4rem;
      align-items: flex-end;
      justify-content: space-between;
    }

    @media #{$normal} {
      margin-top: 3.4rem;
    }

    &__details {
      &__name {
        font-size: 1.6rem;
        font-weight: 900;
      }

      &__job {
        font-size: 1.4rem;
      }
    }

    .InterviewTeaserLarge__date {
      display: block;

      @media #{$mobile-only} {
        display: none;
      }
    }
  }

  .InterviewTeaserLarge__date {
    @media #{$mobile-only} {
      font-size: 1.4rem;
    }
  }

  .theme-label {
    background-color: $color--white;
    color: $color--calypso;
  }

  .overlapping-link:before {
    z-index: 1;
  }

}