@import 'assets/variables';
@import 'assets/mixins';

.SearchAllContentFacets {
  @media #{$tablet} {
    .Facet--item {
      padding-left: 0;
    }
  }

  .FormElement {
    margin-bottom: 2rem;
  }

  .Select__select {
    width: 100%;
  }

  .Searchkit__Facet__label__count {
    display: none;
  }

  .WebformWrapper__placeholder, .WebformWrapper__option,
  .Searchkit__Facet__label__label {
    color: $color--steel-blue;
    font-weight: bold;
    @include font--secondary();
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  .WebformWrapper__option--is-selected, .WebformWrapper__option:hover {
    background-color: $color--kournikova;

    .Searchkit__Facet__label__label {
      color: $color--calypso;
      font-weight: bold;
    }
  }

  .Searchkit__Facet__MenuSelect.WebformWrapper__control--is-focused {
    border-color: $color--calypso-lighter;
    box-shadow: 0 0 1px $color--calypso-lighter;
  }

  .SearchAllContentFacetItem > .Searchkit__Facet__MenuSelect {
    border: solid 2px $color--calypso-lighter;
  }

  .WebformWrapper__control {
    border: none;
  }

}
