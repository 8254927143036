@import 'assets/variables';
@import 'assets/mixins';

// Because of cascading, we need to specify .App here, to avoid using !important,
// as .App ul li selector is targeted in ./styles.
.App .AccordionMenu {
  transition: padding 0.6s ease-in-out;
  margin: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media #{$tablet} {
    transition: max-width 0.6s ease-in-out;
    max-width: 81rem;
  }

  &__item:not(.collapsed) .AccordionMenu__Header>.InlineMenu {
    display: none;
  }

  &__content {
    padding: 2rem 0;
    overflow: scroll;
    max-height: calc(100vh - 6rem);
    background-color: $color--white;
  }

  &__Menu {
    z-index: 1;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    li {
      padding: 0;
      margin: 0;

      &:before {
        display: none;
      }
    }
  }
}

.sticky-outer-wrapper.active {
  position: relative;
  z-index: 100;

  .AccordionMenu {
    max-width: 100vw;
    padding: 0;

    @media #{$mobile-only} {
      margin-top: 6rem;
    }

    &__Header__default {
      display: none;
    }
  }
}