/*
  This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers.
  You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software.
  Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/
@font-face {
  font-family: "Helvetica Neue";
  src: url("../../assets/fonts/HelveticaNeue-Medium.woff2") format("woff2")
}
@font-face{
    font-family:"Helvetica Neue Light";
    src:url("../../assets/fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"),url("../../assets/fonts/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue Bold";
    src:url("../../assets/fonts/1f4274cd-2674-481e-9023-69e33ffca161.woff2") format("woff2"),url("../../assets/fonts/5e8d493c-be01-44b9-962e-504a5cf10dd8.woff") format("woff");
}
