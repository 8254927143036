@import 'assets/mixins';
@import 'assets/grid';

.AnnualReport {
  &__introduction {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background-color: $color--secondary;

    color: white;
    overflow: hidden;

    transition: top 0.2s ease-out, bottom 0.2s ease-out;

    &__wrapper {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height: 100%;

      @media #{$normal} {
        flex-direction: row;
      }
    }

    &__content {
      flex: 1 1;
      position: relative;
      padding: 13.5rem 0 4rem 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: $color--secondary;

      @media #{$tablet} {
        padding-bottom: 13rem;
      }

      p {
        width: auto;
        font-size: 2rem;
        font-weight: 700;

        @media #{$normal} {
          width: 50rem;
        }
      }

      &__image {
        flex: 1 1;

        img {
          width: 100%;
          height: 34rem;
          object-fit: cover;

          @media #{$tablet} {
            width: 100%;
            height: auto;
            object-fit: fill;
          }

          @media #{$normal} {
            width: 100%;
            height: auto;
          }
        }
      }

      .ScrollIndicator {
        padding-top: 8rem;
      }

      @media #{$normal} {
        padding: 0 0 0 14rem;
      }
    }

    .Container {
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: radial-gradient(56.9% 28.67% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    }

    h1 {
      color: white;
      margin-bottom: 4rem;
    }

    svg {
      margin-bottom: 3rem;
    }

  }

  &.started .AnnualReport__introduction {
    bottom: 100%;
  }
}
