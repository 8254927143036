@import 'assets/mixins';
@import 'assets/grid';

.AnnualReport {
  &__outro {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;

    color: white;
    overflow: hidden;

    transition: top 0.2s ease-out, bottom 0.2s ease-out;

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    &__content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $color--secondary;
      text-align: center;
      padding: 0 2rem;

      @media #{$tablet} {
        padding: 0 10rem;
      }
    }

    .Container {
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: radial-gradient(56.9% 28.67% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    }

    h1 {
      color: white;
      margin-bottom: 4rem;
    }

    svg {
      margin-bottom: 4rem;
    }

    p {
      font-size: 2rem;
      font-weight: 700;
      color: $color--white;

      @media #{$tablet} {
        width: 80rem;
        text-align: center;
      }
    }

  }
}
