@import 'assets/variables';
@import 'assets/mixins';

.Select {
  &__inner {
    &__value-container {
      padding: 0 2rem !important;
    }

    &__dropdown-indicator {
      svg {
        width: 22px;
        height: 22px;
      }
    }

    &__control {
      height: 40px !important;
      border-radius: 0 !important;
      border-color: $color--calypso-lighter !important;
      border-width: .2rem !important;
      cursor: pointer !important;

      &--menu-is-open,
      &--is-focused {
        border-radius: 0 !important;
        border-color: $color--calypso-lighter !important;
        box-shadow: none !important;

        &:hover,
        &:focus {
          border-color: inherit !important;
          border-color: $color--steel-blue !important;
          box-shadow: none !important;
        }

      }

      &--menu-is-open,
      &--menu-is-open:hover {
        border-color: $color--steel-blue !important;
      }
    }

    &__option {
      font-family: $font--secondary;
      color: $color--steel-blue !important;
      cursor: pointer !important;

      &--is-focused,
      &--is-selected {
        background-color: $color--kournikova !important;
        color: $color--steel-blue !important;
      }
    }

    &__menu {
      border-radius: 0 !important;
      margin-top: 0 !important;
      box-shadow: none !important;
      border: 2px solid $color--steel-blue !important;
      border-top-width: 0 !important;
    }

    &__menu-list {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &__indicator-separator {
      display: none;
    }

    &__single-value,
    &__placeholder {
      @include font--secondary();
      color: $color--steel-blue !important;
      font-size: 12px !important;
      line-height: 20px !important;
      text-transform: uppercase;
    }
  }

  &__select {
    .indent {
      width: 20px;
      display: inline-block;
    }
  }

  &__inner__single-value {
    .indent {
      width: 0;
    }
  }
}
