@import 'assets/variables';
@import 'assets/mixins';

.ParagraphPodcast {
  margin-bottom: 3rem;

  @media #{$tablet} {
    margin-bottom: 6rem;
  }

  &--has-secondary-links {
    margin-bottom: 0;

    .ParagraphPodcast__secondary_links {
      margin-bottom: 3rem;

      @media #{$tablet} {
        margin-bottom: 6rem;

        a {
          margin-bottom: 0;
        }
      }

      a:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    @include font-size(18);
    line-height: 20px;
    letter-spacing: -0.5px;
    color: $color--fuscous-gray;

    font-family: $font--secondary;
    font-weight: bold;
    margin-bottom: 1.5rem;

    @media #{$normal} {
      @include font-size(20);
      line-height: 24px;
      margin-bottom: 2rem;
    }
  }

  &__body {
    @include font-size(16);
    line-height: 24px;
    color: $color--fuscous-gray;

    margin-bottom: 3rem;

    p {
      margin-bottom: 0;
    }
  }

  &__secondary_links {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
  }

  &__spotify,
  &__apple,
  &__google {
    background-size: 100%;
    display: block;
    background-repeat: no-repeat;
    margin-right: 2rem;
    margin-bottom: 2rem;

    width: 15rem;
    height: 4rem;

    border: 1px solid;
    border-color: #9AB4C9;
    border-radius: 7px;

    @media #{$tablet} {
      width: 16.5rem;
    }

    &:hover {
      border-color: #4081B3;
    }
  }

  &__spotify {
    background-image: url('../../../assets/images/podcasts/spotify-podcasts-badge.svg');
  }

  &__apple {
    background-image: url('../../../assets/images/podcasts/apple-podcasts-badge.svg');
  }

  &__google {
    background-image: url('../../../assets/images/podcasts/google-podcasts-badge.svg');
  }
}
