@import 'assets/variables';
@import 'assets/mixins';

.StaffTeaser {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  background-color: $color--white;
  transition: background-color 0.33s linear;
  width: 100%;

  @media #{$tablet} {
    display: flex;
    flex-direction: row;
    height: unset;
  }

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  &__content {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: 100%;

    @media #{$tablet} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

      &:hover {
        .StaffTeaser--hasDetailPage & {
          cursor: pointer;
          background-color: $color--kournikova;
          transition: 0.1s ease-in-out;
          text-decoration: none;
          color: inherit;
      }
    }
  }

  &__imageWrapper {
    background-color: $color--black-squeeze;

    @media #{$tablet} {
      width: 100%;
      height: 19rem;
    }

    img {
      width: 12.5rem;
      height: 12.5rem;

      @media #{$tablet} {
        width: 100%;
        height: 100%;
      }
    }
  }

&__imageNormal {
    display: none;
    @media #{$tablet} {
      display: block !important;
      object-fit: cover;
    }
  }

  &__imageMobile {
    object-fit: cover;
    @media #{$tablet} {
      display: none !important;
    }
  }

  &__contentWrapper {
    width: 100%;
    flex: 1 1 auto;
    padding: 1.5rem;
    display: flex;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    height: 12.5rem;

    @media #{$tablet} {
      padding: 2rem;
      height: 19rem;
      justify-content: space-between;
    }

    &__name {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    h1 {
      margin-bottom: .5rem;
      letter-spacing: -0.5px;
    }
  }

  .social-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    gap: 1rem;

    a {
      z-index: 3;
    }

    .icon-before {
      font-size: 3rem;

      &:hover {
      &:before {
        background-position-y: 0;
      }
      }
    }

    .Button {
      margin-left: auto;

      @media #{$mobile-only} {
        display: flex;
        height: 4.2rem;
        width: 4.2rem;
        padding: 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        position: absolute;
        right: 0;
        bottom: 0;
        background: $color--steel-blue;
        font-size: 0;
        padding: 0;

        &:after {
          margin-left: -0.5rem;
          background-position-y: -3em;
        }
      }
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }
}
