@import 'assets/variables';
@import 'assets/mixins';

.PillarOverviewSlider {
  width: 100%;
  border-bottom: 2px solid $color--mystic;

  @media #{$normal} {
    max-width: calc(100vw - 240px);
  }

  @media screen and (min-width: 1440px) {
    max-width: 1240px;
  }

  > .row {
    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }
  }

  &__item-wrapper {
    border-bottom: 2px solid $color--border;
    @media #{$tablet} {
      border-bottom: none;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: inset -2px 0 $color--border;
        pointer-events: none;
        z-index: 100;
      }
    }
  }
}

.slick-slide:last-child {
  border-bottom: none;
  opacity: 0;

  .OverviewIntro__content,
  .TaxonomyTermThemeTeaser__content,
  .NewsTeaser__content,
  .DownloadsTeaser__content,
  .OpinionsTeaser__content {
    box-shadow: none;
  }
}

.slick-slider {
  &:hover {
    .SliderRelated__prev--visible,
    .SliderRelated__next {
      opacity: 1;
      pointer-events: all;
      transition: opacity .1s linear, background-color 0.33s linear;
    }
  }
}
