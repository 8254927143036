@import 'assets/variables';
@import 'assets/mixins';

.DossiersAllOverview {
  overflow: auto;

  > .row {
    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }
  }
}
