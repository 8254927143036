@import 'assets/variables';
@import 'assets/mixins';

.CourseDateTeaser {
  padding-bottom: 3rem;

  &__info {
    width: 100%;
    height: 100%;
    padding: 2rem;
    border: 1px solid $color--border;

    &__item {
      display: flex;
    }

    &__title {
      flex: 0 1 25%;
      @include body--bold();
      font-weight: 500;
    }

    &__body {
      flex-grow: 1;
    }

    &__location {
      @include font--secondary();
      font-size: 1.8rem;
      line-height: 2rem;
      padding-bottom: 2rem;
    }

    &__availability {
      padding: 2rem 0 1rem;
    }
  }

  .Button {
    width: 100%;
    text-align: left;
  }
}
