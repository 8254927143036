@import 'assets/variables';
@import 'assets/mixins';

.FAQ {
  margin-bottom: 3rem;

  @media #{$tablet} {
    margin-bottom: 6rem;
  }

  &__accordion {
    background: $color--border;
  }
}
