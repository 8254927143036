@import 'assets/variables.scss';

.App ol.Breadcrumbs {
  position: relative;
  margin-bottom: 3rem;
  padding: 0;
  top: 1rem;

  @media #{$tablet} {
    position: absolute;
    top: 3rem;
    margin-bottom: 0;
  }

  li.Breadcrumbs__item {
    list-style: none;
    display: inline-block;
    padding-left: 0;
    line-height: 1.6rem;
    margin-bottom: 0;

    @media #{$mobile-down} {
      display: none;

      &:nth-child(2) {
        display: inline-block;

        &:before {
          transform: scale(-1);
        }
      }
    }

    &:before {
      content: none;
    }

    & + li {
      padding-left: 2.6rem;
      position: relative;

      &:before {
        content: '>';
        position: absolute;
        left: 0.5rem;
        top: 2px;
        width: 1.6rem;
        height: 1.6rem;
        padding: 0;
        display: inline-block;
        font-weight: 600;
        font-family: $font--secondary;
        color: #9AB4C9;
        line-height: 1.2rem;
        font-size: 1.6rem;
        text-align: center;
      }
    }

    a {
      display: inline-block;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: $color--steel-blue;
      text-decoration: none;

      &.icon-before {
        height: 1.6rem;
        width: 1.6rem;
        font-size: 1.6rem;
        position: relative;

        span {
          display: none;
        }

        &:before {
          content: "";
          position: absolute;
          top: 3px;
        }
      }
    }

    // &:first-child {
    //   a:not(.icon) {
    //     &:before {
    //       content: "";
    //     }
    //   }
    // }
  }
}
