@import 'assets/variables';
@import 'assets/mixins';

.AccordionMenu__Menu__Item {
  .Accordion__item__title button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
    color: $color--calypso;

    .icon {
      @include font-size(24);

      flex: 0 0 auto;
      transform: rotate(-90deg);
      transition: transform 0.1s linear;
    }
  }

  &.collapsed button .icon {
    transform: rotate(90deg);
    transition: transform 0.1s linear;
  }

  .InlineMenu__nav {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 1rem;
  }

  .InlineMenu__nav-item {
    text-decoration: none;
  }

  .InlineMenu__nav-item a {
    @include body--regular;

    display: flex;
    align-items: flex-start;
    padding: 0.2rem 2rem;
    padding-left: 1.6rem;
    border-left: 0.4rem solid transparent;
    color: $color--calypso;
    background-color: transparent;
    text-decoration: none;
    transform: translateX(0);
    transition: transform 0.1s linear;

    &.icon-before:before {
      @include font-size(16);

      margin-top: 0.3rem;
      margin-right: 1rem;
    }

    &:hover,
    &:active,
    &:focus {
      transform: translateX(1rem);
    }
  }

  .InlineMenu__nav-item.is-active a {
    border-left-color: $color--fuscous-gray;
    background-color: $color--kournikova;

    &:hover,
    &:active,
    &:focus {
      transform: translateX(0);
    }
  }
}
