@import 'assets/variables';
@import 'assets/mixins';

.Experts {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @media #{$tablet} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: stretch;
    }

    .StaffTeaser {
      border: 2px solid $color--border;

      &__contentWrapper {
        @media #{$tablet} {
          padding: 2rem;
        }
      }
    }
  }
}
