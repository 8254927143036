@import 'assets/variables';
@import 'assets/mixins';

.NotFound {
  height: 100%;
  width: 100%;
  padding-top: 12rem;
  padding-bottom: 12rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;


  @media #{$tablet} {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  h1 {
    margin-bottom: 2rem;

    @media #{$normal} {
      margin-bottom: 4rem;
    }
  }
}
