@import 'assets/variables';
@import 'assets/mixins';

.container--small {
  .Cta {
    margin-bottom: 3rem;

    @media #{$tablet} {
      margin-bottom: 6rem;
    }
  }

}

.type-blue .Cta {
  background-color: $color--mystic;
}

.type-yellow .Cta {
  background-color: $color--light-yellow;
}

.Cta {
  position: relative;
  padding: 0;

  &__image {
    max-width: 17rem;
  }

  &__contentWrapper {
    flex: 1 1 auto;
    padding: 3rem 2rem;
    padding-bottom: 6rem;
    display: block;
    text-align: left;

    &__image {
      text-align: center;
      margin-bottom: 2rem;

      @media #{$tablet} {
        margin-bottom: 0;
      }
    }

    .small & {
      padding-bottom: 3rem;
    }

    padding-bottom: 3rem;

    @media #{$tablet} {
      height: 100%;
      padding: 6rem;
      padding-bottom: 6rem;

      .small & {
        padding-bottom: 6rem;
      }
    }
  }

  &__title {
    color: $color--fuscous-gray;
    font-size: 2.2rem;
    @include font--secondary();
    line-height: 2.4rem;
    margin-bottom: 1.5rem;

    @media #{$normal} {
      font-size: 3rem;
      line-height: 3.4rem;
    }
  }

  &__body {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  &__button {
    margin-top: 3rem;
  }
}
