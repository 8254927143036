@import 'assets/variables';
@import 'assets/mixins';

.MainMenu {
  @include font--secondary();
  display: none;
  width: 100%;
  padding: 3rem;
  flex: 1 0 auto;

  @media #{$normal} {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex: 1 1 auto;
  }

  &__section {
    margin-bottom: 6rem;
  }

  .menu--desktop-navigation {
    display: flex;
  }

  .Menu {
    width: 100%;

    &.menu--main-navigation {
      margin-bottom: 6rem;
    }
  }

  &__item,
  &__main__item {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    &__link {
      display: inline-block;
      text-decoration: none;
      cursor: pointer;
      position: relative;

      > div {
        display: flex;
        justify-content: center;

        .icon-hover {
          opacity: 0;
          margin-left: .5rem;
          @include font-size(22);
          transition: opacity 0.1s linear;
        }
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;

        > div {
          color: $color--kournikova;
          transition: color 0.1s linear;

          .icon-hover {
            opacity: 1;
          }
        }

        .Button--primary {
          color: $color--fuscous-gray;
        }

        .menu-icon-hover {
          transition: opacity .1s linear;

          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

      }

      &__title {
        color: $color--white;
        @include font--secondary;
        @include font-size(16);
        @include font--secondary();
        transition: color 0.1s linear;
        text-align: left;
        line-height: 2.2rem;
        text-transform: uppercase;

        @media #{$normal} {
          text-align: right;
        }

        &.Button {
          .icon-hover {
            display: none;
          }

          &.Button--primary {
            border: 2px solid $color--kournikova;

            &:hover {
              color: white;
              background-color: $color--jaffa;
              border-color: $color--jaffa;
            }
          }

          &.Button--secondary {
            border: 2px solid $color--porcelain;
            color: $color--porcelain;
            background-color: transparent;

            &:hover {
              color: white;
              background-color: $color--jaffa;
              border-color: $color--jaffa;

              &:after {
                background-position-y: -17em;
              }
            }
          }
        }

      }
    }
  }

  &__main {
    padding: 3rem 3rem;

    @media #{$tablet} {
      padding: 3rem;
    }
  }

  &__mobile {
    display: flex;
    align-items: center;

    @media #{$normal} {
      display: none;
    }

    .Hamburger {
      z-index: 1000000;

      &__wrapper {
        height: 6rem;
        display: flex;
        place-items: center;
      }

      &__icon {
        font-size: 3rem;

        @media #{$tablet} {
          font-size: 2.2rem;
        }
      }

      &__link {
        margin-right: 1rem;
        color: $color--white;
        @include font--secondary();

        @media #{$tablet} {
          margin-right: 2rem;
        }

        span {
          display: none;
          @media #{$tablet} {
            display: inline-block;
          }
        }

        .icon {
          display: inline-block;
          font-size: 3rem;
          background-position-y: -3em;

          @media #{$tablet} {
            font-size: 2.2rem;
            margin-left: .5rem;
          }
        }
      }

      &__button {
        cursor: pointer;

        .Hamburger__icon {
          display: inline-block;
        }

        span {
          margin-right: .5rem;
          font-size: 1.6rem;
          @include font--secondary();
          line-height: 2.2rem;
          letter-spacing: normal;
          color: #fff;
          display: none;

          @media #{$tablet} {
            display: inline-block;
          }
        }
      }

      .title {
        display: none;
      }

      .hamburger-menu {
        span {
          background-color: $color--white;
        }
      }
    }

    &__menu {
      display: block;
      overflow-y: auto;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      position: absolute;
      z-index: -1;
      padding-top: 9.5rem;
      background: $color--primary;
      opacity: 0;
      transition: opacity .33s linear;
      pointer-events: none;

      .Menu {
        padding-top: 3rem;
        padding-left: 2rem;

        @media #{$mobile} {
          padding-left: 3rem;
        }
      }

      .menu--main-navigation-bottom {
        position: absolute;
        left: 3rem;
        bottom: 1rem;

        .MainMenu__item:last-child {
          display: none;
        }
      }
    }

    &--active {
      pointer-events: all;

      .MainMenu__mobile__menu {
        pointer-events: all;
        transition: opacity .33s linear;
        opacity: 1;
        @media #{$normal} {
          transition: none;
        }
      }
    }
  }
}
