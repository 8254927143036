@import 'assets/variables';
@import 'assets/mixins';

.GroupedContentThemeDetail {
  &__item-wrapper {
    border-bottom: 2px solid $color--border;
    @media #{$tablet} {
      border-right: 2px solid $color--border;
    }
  }
}
