@import 'assets/mixins';
@import 'assets/grid';

.Header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  z-index: 102;
  padding: 2rem;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.0001) 100%);

  &__logo {
    flex: 0 0 100px;

    img {
      height: 2.5rem;
      width: auto;
    }
  }

  &__title {
    color: $color--white;
    padding-left: 2rem;
    font-size: 2rem;
    font-weight: 700;
  }

  @media #{$tablet} {
    padding: 2.5rem 4.5rem;
  }

  @media #{$normal} {
    padding: 3rem 5rem;
  }
}

.ThemeItem .Header {
  background: none;

  &__title {
    font-size: 2rem;
    color: $color--calypso;
  }
}
