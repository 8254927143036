@import 'assets/variables';
@import 'assets/mixins';

.MembersAllOverview {
  &__item-wrapper {
    border-bottom: 2px solid #c2d2de;
    @media #{$tablet} {
      border-right: 2px solid $color--border;
    }

  }
}
