@import 'assets/variables';
@import 'assets/mixins';

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;

  @media #{$normal} {
    display: block;
    min-height: 100vh;
    height: 100%;
    width: 29.1rem;
    left: unset;
    background: $color--fuscous-gray;
  }
}

.App__searchbar  {
  .SearchBar__inner input {
    outline-width: 0;
    border: 2px solid $color--calypso-lighter;
    border-radius: unset;
    color: $color--primary-lighter;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-overflow: ellipsis;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: $color--elephant;
    opacity: 0.6;
    cursor: pointer;
  }
}

@media #{$tablet-down} {
  div.show-nav {
    height: 10rem;

    .Navigation__search_expand {
      top: 10rem;
    }
  }
}

#Navigation__desktop_search_button:focus {
  outline: none;
}

.Navigation__search_expand {
  z-index: 1000;
  .SearchBar__submit {
    &::before,
    &::after {
      background-position: -1em 0em;
    }
  }

  input {
    border: unset;
  }

  @media #{$tablet-down} {
    position: fixed;
    width: 100%;
    top: 6rem;
    left: 0;
    z-index: 1000;

    input {
      width: 100%;
      height: 6rem;
      padding: 2rem;
    }

    .SearchBar__submit {
      height: 6rem;

      &::before,
      &::after {
        top: 1.8rem;
      }
    }
  }

  @media #{$normal} {
    position: fixed;
    width: 116rem;
    z-index: 1000;
    margin-left: 14rem;
    top:8rem;

    input {
      height: 8rem;
      width: 100%;
      padding: 3rem;
      outline-width: 0;
      border: 2px solid transparent;

      &:focus {
        border: 2px solid $color--kournikova;
      }
    }

    .SearchBar__submit {
      height: 8rem;

      &::before,
      &::after {
        top: 2.8rem;
        background-position: -1em 0em;
      }
    }
  }
}

.Navigation {
}

.Navigation--mobile {
  width: 100%;
  height: 6rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
  background-color: $color--calypso;
  transition: height 0.33s cubic-bezier(.2,0,.2,1), width 0.33s cubic-bezier(.2,0,.2,1), padding-left .33s cubic-bezier(.2,0,.2,1);

  @media #{$normal} {
    transition: none;
  }

  #Navigation__desktop_search_button {
    display: none;
  }

  @media #{$normal} {
    width: 29rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: $color--fuscous-gray;

    &:after {
      content: "";
      width: 5000rem;
      height: 100%;
      position: absolute;
      background-color: $color--fuscous-gray;
      top: 0;
      right: 0;
      z-index: -2;
    }
  }

  &--active {
    @media #{$tablet-down} {
      height: 9.5rem;
      padding-left: 3rem;
      transition: height 0.33s cubic-bezier(.2,0,.2,1), padding-left .33s cubic-bezier(.2,0,.2,1);
    }
  }

  &__logo-container {
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;

    @media #{$normal} {
      width: 100%;
      height: auto;
      display: block;
      background-color: $color--calypso;

      &:before {
        content: "";
        display: block;
        padding-top: 29rem;
      }

      &:after {
        content: "";
        width: 200vw;
        height: 100%;
        position: absolute;
        background-color: $color--calypso;
        top: 0;
        right: 0;
        z-index: -1;
      }
    }

    &__inner {
      @media #{$normal} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        padding: 3rem;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-content: stretch;
        align-items: flex-end;
      }
    }
  }

  &--hidden {
    margin-top: -125px;
    transition: background-color 0.333s linear, margin 0.333s ease-out;
  }

  &--visible {
    margin-top: 0;
    transition: background-color 0.333s linear, margin 0.333s ease-out;
  }

  &--scrolled {
    background-color: $color--white;

    &.Navigation--variant {
      background-image: none;

      .MainMenu__item__title,
      .FontSizeSwitcher__button:before {
        transition: color 0.333s linear;
        color: $color--primary !important;
      }

      .Hamburger__wrapper {
        background-color: $color--primary !important;
      }
    }
  }


  &--variant {

    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.08) 64%, rgba(0, 0, 0, 0) 82%);

    .MainMenu__item__title,
    .FontSizeSwitcher__button:before {
      color: white !important;
      transition: color 0.333s linear;
    }

    .Hamburger__wrapper {
      background-color: $color--white !important;
      transition: color 0.333s linear;
    }
  }

  .container,
  .col-12 {
    position: initial !important;
  }
}

.Navigation--desktop {

  #Navigation__desktop_search_button {
    padding-bottom: 0.5rem;
    z-index: 100;
  }

  #Navigation__bg {
    position: absolute;
    height: 29rem;
    width: 250rem;
    z-index: 100;
    left: -250rem;
    background: $color--calypso;
  }

  #MainMenu__bg {
    position: absolute;
    width: 250rem;
    left: -250rem;
    background: $color--fuscous-gray;
    height: 100%;
    top: 0;
  }

  .Navigation__logo-container {
    background: $color--calypso;
    height: 29rem;

    .Button {
      float: right;
      border: none;
      margin-right: 3rem;
      padding-left: 0;
      padding-right: 3rem;
      margin-top: 1.7rem;

      &::before, &::after {
        right: 0;
        background-position: -1em -3em;
      }

      &:hover, &:focus {
        background: unset;
        color:$color--white;
        border-bottom: 3px solid $color--elephant;
      }
    }

    &__inner {
      height: 100%;

      div {
        height: 100%;
        padding: 3rem;
        padding-top: 2rem;
        padding-bottom: 3.4rem;
        top: 0.2rem;
        position: relative;
      }

      a.Logo {
        background-position: bottom left;
        background-size: 100% auto;
        margin-top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.show-nav {
  .Hamburger {
    .hamburger-menu {
      span:first-child {
        top: calc(50% - 2px);
      }

      span:last-child {
        top: calc(100% - 4px);
      }
    }
  }
}
