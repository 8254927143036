@import 'assets/variables';
@import 'assets/mixins';

.AccordionMenu__Header {
  @include link-box('before');

  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 6rem;
  padding: 1rem 1.5rem;
  background-color: $color--mystic;

  @media #{$tablet} {
    height: 8rem;
  }

  // Styling collapsed view, so we can add some transition.
  &+div[aria-hidden='true']>div {
    display: block !important;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s linear;
  }

  &+div[aria-hidden='false']>div {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.1s linear;
  }

  &>button {
    @include body--bold;

    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
    min-width: 2.2rem;
    height: 2.2rem;
    padding: 0;
    border: 0;
    margin-left: auto;
    color: $color--steel-blue;
    background-color: transparent;

    &:focus {
      outline: 0;
    }

    .icon {
      @include font-size(22);
    }

    .icon-caret {
      transform: rotate(90deg);
    }
  }

  &__item,
  &__default {
    color: $color--calypso;
  }

  &__item .truncate {
    height: 100%;
  }

  &__item .body-bold {
    line-height: 1.6rem;
  }

  &__item .body-small {
    line-height: 1.4rem;
  }

  .InlineMenu,
  .InlineMenu__nav {
    width: 100%;
    max-width: calc(100% - 3rem);
    height: 100%;
  }

  // Hide all but the active item or the first non active item.
  .InlineMenu__nav-item:not(.is-active):not(.undefined:first-child) {
    display: none;
  }

  .InlineMenu__nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: 0;
    text-decoration: none;

    &:before {
      display: none;
    }
  }
}