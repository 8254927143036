@import 'assets/variables';
@import 'assets/mixins';

.ViewWithFilters {
  .View__filters {
    width: 100%;
    background-color: $color--mystic;

    margin: 0;

    padding: 0 2rem 2rem 2rem;

    @media #{$normal} {
      padding: 0 3rem 3rem 3rem;
    }

    .Form__element {
      margin: 0;
      width: 26rem;
      display: inline-block;

      @media #{$tablet} {
        margin: 0 .5rem;

        &:first-of-type {
          margin-right: 3rem;
        }
      }

      label {
        text-transform: uppercase;
      }

      &:first-of-type {
        margin-left: 0;
        z-index: 10;
      }

      .Select__select {
        width: 100%;
      }
    }
  }
}
