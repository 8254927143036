@import 'assets/variables';
@import 'assets/mixins';

.ParagraphLivechart {
  &__wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 3rem;

    @media #{$tablet} {
      margin-bottom: 6rem;
    }
  }
}
