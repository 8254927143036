@import 'assets/variables';
@import 'assets/mixins';

.SearchResults {

  .Searchkit__Results li{
    padding-left: 0;
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }

  &__no_results {
    background: $color--light-yellow;
    padding: 3rem 4rem 3rem 4rem;
    text-align: center;
    margin-bottom: 6rem;

    @media #{$normal} {
      padding: 4rem 10rem 4rem 10rem;
    }

    &__title {
      margin-bottom: 1.5rem;
    }

    &__body {
      margin-bottom: 2rem;
    }

    &__action {
      .Button {
        padding: 1rem 2.5rem 1rem 2.5rem;
        border: unset;
      }
      .Button::before,
      .Button::after {
        display: none;
      }
    }
  }

  .Search__result_item {
    a {
      color: inherit;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        .Search__result_item__title {
          color: $color--calypso;
          text-decoration: underline;
        }
      }
    }
    &__type {
      text-transform: uppercase;
      @include font--medium();
      font-size: 1.2rem;
      margin-bottom: 0.5rem;

      &__star {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: $color--steel-blue;
        font-weight: bold;
        position: relative;
        top: 0.2rem;
      }

      &__pdf {
        background: $color--black-squeeze;
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      }
    }

    &__title {
      @include h3();
      font-weight: bold;
      margin-bottom: 1.5rem;
      @media #{$normal} {
        font-size: 2rem;
      }
    }

    &__body {
      margin-bottom: 1.5rem;
    }

    &__date {
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 2px solid $color--porcelain;
      @media #{$normal} {
        padding-bottom: 3rem;
        margin-bottom: 3rem;
      }
    }
  }
}