@import 'assets/variables';
@import 'assets/mixins';

.Search {
  width: 100%;
  background: $color--white;

  .PageHeader {
    background-color: $color--mystic;

    @media #{$tablet-down} {
      min-height: 20rem;
    }

    &__content {
      width: 100%;

      @media #{$normal} {
        padding: 0 6rem 3rem 6rem;
      }

      &__searchbar {

        position: relative;

        .Searchkit__SearchBar {
            width: 100%;

            .Button {
              font-weight: normal;
            }

            input {
              width: 100%;
              height: 6rem;
              padding: 2rem;
              font-size: 1.6rem;
              line-height: 2.4rem;
              @include font--medium();
              outline-width: 0;
              border: 2px solid $color--calypso-lighter;
              border-radius: unset;
              padding-right: 4.5rem;
              text-overflow: ellipsis;
              color: $color--fuscous-gray;


              &:placeholder-shown {
                color: $color--primary-lighter;
              }

              &:focus, &:active  {
                border: 2px solid $color--kournikova;
                outline: none;
                outline-width: 0;
              }
            }

            @media #{$mobile-only} {
              .SearchBar__submit {
                padding-left: 1rem;

                span {
                  display: none;
                }
              }
            }

            button {
              height: 6rem;

              &::before {
                top: 1.8rem;
                background-position: -1em 0;
              }

              &::after {
                top: 1.8rem;
                background-position: -1em -3em;
              }
            }
          }

        &__icon {
          display: inline-block;
          position: absolute;
          top: 0.7rem;
          right: 12rem;
          font-size: 2.2rem;
          height: 4.4rem;
          width: 4.4rem;

          @media #{$mobile-only} {
            right: 6rem;
          }

          .icon {
            position: relative;
            top: 0.8rem;
            left: 1.1rem;
          }


          &:hover {
            cursor: pointer;

            .icon {
              background-position-y: -1em;
            }
          }
        }
      }
    }


    .content {
      @media #{$normal} {
        padding: 0;
      }
      h1 {
        color: $color--fuscous-gray;
      }
    }
  }

  .Breadcrumbs {
    @media #{$mobile-only} {
      display: none;
    }
  }

  .Searchkit__result_section {
    @media #{$normal} {
      padding-right: 5rem;
    }
  }

  .SearchContent {
    margin-top: 3rem;
    margin-bottom: 6rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media #{$normal} {
      margin-top: 7.5rem;
      margin-bottom: 12rem;
    }

    .facets-label, .Searchkit__Facet__label__count {
      font-weight: normal;
    }

    &__facet_type {
      position: relative;

      .Searchkit__Facet__RefinementCheckboxes__inner {
        display: inline-block;
      }

      .facets-label {
        margin-bottom: 0.5rem;
      }

      .WebformCheckboxes__checkbox__input {
        margin-right: 1rem;

        .Searchkit__Facet__label {
          line-height: 4rem;
          text-transform: uppercase;
          span {
            @include font--secondary();
            font-weight: normal;
            font-size: 1.2rem;
            margin-left: 0rem;
          }
        }

        // .Searchkit__Facet__label__label {
        //   font-weight: bold;
        //   @include font--secondary();
        //   text-transform: uppercase;
        //   font-size: 1.2rem;
        // }

        .Searchkit__Facet__label__count {
          display: none;
        }
      }
    }
  }

  h1 {
    margin-bottom: 2.5rem;
  }
}

.Searchkit__Header {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 3rem;

  .FormElement {
    margin-bottom: 0;
  }

  .Searchkit__Stats {
    text-transform: uppercase;
    @include h5();
    font-weight: normal;
    margin-bottom: 1rem;
  }
}

.SearchAllContent__SortBy {

  &__inner {
    display: flex;
    column-gap: get-spacer(5);
  }

  input {
    display: none;
  }

  > label {
    display: none;
  }

  span > span {
    font-weight: normal
  }

  &__inner label {
    @include h5;
    cursor: pointer;
    color: $color--calypso;
    opacity: 0.5;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
      color: $color--fuscous-gray;
    }

    &:before {
      display: none;
    }


    // Checked state.
    &.icon-variant-1, &:focus {
      opacity: 1;
      padding-bottom: 0.5rem;
      color: $color--calypso;
      border-bottom: 2px solid $color--calypso;
    }
  }
}