@import 'assets/variables';

.SearchAllContent__Results__EmptyState {
  text-align: center;
  padding: 4rem;
  background-color: rgba(253, 236, 211, 0.3);

  display: flex;
  flex-direction: column;
  gap: 2rem;

  button.Button {
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 0;
  }
}
