@import 'assets/variables';
@import 'assets/mixins';

.NewsAllOverview {
  overflow: auto;

  >.row {
    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }
  }

  &__item-wrapper {
    border-bottom: 2px solid $color--border;

    .NewsTeaser__content,
    .MemberTeaser__content {
      @media #{$tablet} {
        box-shadow: inset -2px 0 $color--border;
      }
    }

    .NewsTeaser__truncate {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      hyphens: auto;
    }

    &:nth-child(3n+3) {
      .NewsTeaser__content {
        box-shadow: none
      }
    }
  }
}