@import "assets/variables.scss";

.DossierTeaser {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: inherit;
  color: inherit;

  &:hover {
    background-color: $color--kournikova;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    text-decoration: inherit;
  }

  &__image-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;

    .LazyLoadWrapper {
      z-index: 2;
    }

    .DossierTeaser__image {
      position: relative;
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      object-fit: cover;
      z-index: 1;

      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        aspect-ratio: 16/9;
        background-color: $color--black-squeeze;
        z-index: 0;
      }
    }
  }

  &__content {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__title {
      margin-bottom: 1rem;
    }

    &__body {
      margin-bottom: 2rem;
    }

    &__date {
      font-size: 1.4rem;
      margin-top: auto;
      margin-bottom: 0;
    }

    &__tagsWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__label {
      font-family: $font--primary;
      color: var(--primary-Dark-grey, #4e4e4c);
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.6rem;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
    }

    &__status {
      position: relative;
      font-family: $font--medium;
      padding: 0.8rem 0.8rem 0.8rem 0.5rem;
      background-color: $color--black-squeeze;
      text-align: center;
      width: fit-content;
      z-index: 1;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.6rem;
      text-transform: uppercase;
      color: $color--calypso;

      &:after {
        content: "";
        position: absolute;
        width: 2.0616rem;
        height: 2.0616rem;
        transform: rotate(45deg);
        top: 0.5rem;
        left: -1.1rem;
        z-index: -1;
        background: $color--black-squeeze;
      }
    }
  }

  &:not(--small) {
    .DossierTeaser__content {
      &__title {
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem;
      }
    }
  }

  @media #{$tablet} {
    &--large {
      flex-direction: row;
      align-items: flex-start;

      .DossierTeaser__image-container {
        max-width: 38.2rem;

        .DossierTeaser__image {
          width: 38.2rem;
          aspect-ratio: 1/1;

          &-overlay {
            width: 38.2rem;
            height: 38.2rem;
          }
        }
      }

      .DossierTeaser__content {
        height: 38.2rem;
        padding: 6rem;

        &__title {
          margin-bottom: 1.5rem;
        }

        &__date {
          font-size: 1.6rem;
        }
      }
    }
  }
}
