@import 'assets/mixins';
@import 'assets/grid';

.Modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background-color: white;
  transition: opacity 0.3s linear;
  z-index: 9999;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &__content {
    overflow-y: auto;
    height: 100%;
  }

  .Button {
    border: none;
    background-color: transparent;
    color: $color--calypso;
    position: absolute;
    right: 0;
    top: 1.8rem;
    z-index: 105;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    padding-right: 2rem;

    @media #{$tablet} {
      right: 2rem;
      top: 2.3rem;
    }

    @media #{$normal} {
      right: 2rem;
      top: 2.8rem;
    }

    .icon {
      margin-left: 0.5rem;
    }

    &:active, &:focus {
      outline: none;
    }

    &:hover {
      .icon {
        background-position-y: -3em;
      }
    }

    &:before, &:after {
      content: none;
    }
  }
}
