@import 'assets/variables.scss';

.TitleHeaderBlock {
  &__heading {
    display: flex;
    justify-content: center;
    background: $color--mystic;
    padding: 6rem 0.5rem 3rem;
    border-bottom: 2px solid $color--border;


    @media #{$tablet} {
      padding: 6rem 3rem 3rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h2 {
        text-transform: uppercase;
        margin-bottom: 0;
      }

      p {
        margin: 1.5rem 0;

        @media #{$tablet} {
          margin-bottom: 0;
        }
      }

      @media #{$tablet} {
        padding-left: 0 !important; // Ovewrite column padding.
      }
    }

    &__link {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-top: 1.5rem;

      @media #{$tablet} {
        margin-top: 0;
        padding-right: 0 !important; // Ovewrite column padding.
      }

      .Button {

        width: 100%;
        text-align: left;

        @media #{$tablet} {
          width: unset;
          text-align: unset;
        }
      }
    }
  }
}
