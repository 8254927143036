@import 'assets/variables';
@import 'assets/mixins';

.Logo {
  background-repeat: no-repeat;
  transition: height 0.33s cubic-bezier(.2,0,.2,1), width 0.33s cubic-bezier(.2,0,.2,1), margin .33s cubic-bezier(.2,0,.2,1);
  width: 8rem;
  height: 3rem;
  z-index: 10000;
  display: block;
  margin-top: 1.5rem;
  background-size: cover;
  background-image: url('../../assets/images/logo/logo-light.svg');
  text-indent: -999rem;

  @media #{$normal} {
    transition: none;
  }
  @media #{$normal} {
    width: 23rem;
    height: 8.7rem;
  }

  &--footer {
    width: 12rem;
    height: 4.5rem;
    background-image: url('../../assets/images/logo/logo-blue.svg');

    @media #{$tablet} {
      width: 18.1rem;
      height: 6.8rem;
    }
  }

  &--active {
    width: 12rem;
    height: 4.5rem;
    margin-top: 3rem;
    transition: height 0.33s cubic-bezier(.2,0,.2,1), width 0.33s cubic-bezier(.2,0,.2,1), margin .33s cubic-bezier(.2,0,.2,1);

    @media #{$normal} {
      transition: none;
    }
  }
}
