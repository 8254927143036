@import 'assets/variables';
@import 'assets/mixins';

.OpinionsAllOverview {
  overflow: auto;

  > .row {
    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }
  }

  &__item-wrapper {
    border-bottom: 2px solid $color--border;

    .OpinionTeaser__content {
      @media #{$tablet} {
        box-shadow: inset -2px 0 $color--border;
      }
    }

    &:nth-child(3n+3) {
      .OpinionTeaser__content {
        box-shadow: none
      }
    }

    &:nth-last-child(-n+3) {
      @media #{$tablet} {
        border-bottom: none;
      }
    }

    &:last-of-type {
      @media #{$mobile-only} {
        border-bottom: none;
      }
    }
  }
}
