@import 'assets/variables';
@import 'assets/mixins';

.CtaGroupItem {
  background-color: $color--white;

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
      padding-top: 133.33%;
    }
  }

  &:hover,
  &:focus {
    .link-icon {
      background-color: $color--elephant;
    }

    .CtaGroupItem__imageWrapper {
      transform: translateY(-.5rem);
    }
  }

  .icon-caret {
    @include font-size(22);
  }

  .link-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    background-color: $color--steel-blue;
    transition: background .2s ease-in-out;
  }

  &__content {
    padding: 3rem;
    display: flex;
    align-items: center;

    @media #{$tablet} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 3rem 5rem;
      flex-direction: column;
    }

    @media #{$normal} {
      padding: 6rem;
    }
  }

  &__imageWrapper {
    display: flex;
    flex: 0 0 8rem;
    justify-content: center;
    transition: transform .2s ease-in-out;

    @media #{$tablet} {
      width: 100%;
    }

    img {
      max-height: 8rem;

      @media #{$tablet} {
        max-height: 14rem;
      }

      @media #{$normal} {
        max-height: 16rem;
      }

      @media #{$wide} {
        max-height: 20rem;
      }
    }
  }

  &__contentWrapper {
    padding: 0 3rem;

    @media #{$tablet} {
      padding: 3rem 0;
    }

    @media #{$normal} {
      padding: 3rem 0;
    }

    .title {
      text-transform: uppercase
    }

    .node--page--body {
      margin-top: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }
}
