@import 'assets/variables';
@import 'assets/mixins';

.OpinionTeaserLarge {
  position: relative;
  background-color: $color--black-squeeze;
  transition: background-color 0.33s linear;
  border-bottom: 2px solid $color--mystic;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    @media #{$tablet} {
      display: flex;
      flex-direction: row;
    }

    .reversed & {
      @media #{$tablet} {
        flex-direction: row-reverse;
      }
    }
  }

  &__imageWrapper {
    height: auto;
    width: 100%;
    padding: 2rem;
    padding-top: 0;
    padding-bottom: 0;

    @media #{$tablet} {
      width: calc(100% / 3);
      height: 100%;
      padding: 0;
      flex: 1 0 auto;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: flex-end;
    }

    img {
      width: 100%;
      height: auto;

      @media #{$tablet} {
        width: calc(100% - 5rem);
      }

      @media #{$normal} {
        width: calc(100% - 7rem);
        // height: calc(100% - 4.5rem);
      }
    }

    h1 {
      letter-spacing: -.05rem;
    }
  }

  &__contentWrapper {
    flex: 1 1 auto;
    padding: 3rem 2rem;
    display: block;
    text-align: left;

    @media #{$tablet} {
      height: 100%;
      padding: 3rem;
    }

    @media #{$normal} {
      padding: 6rem;
    }
  }

  .node--sub-heading {
    font-size: 1.6rem;
  }

  .node--sub-heading-quote {
    display: inline-block;
    margin-top: 1.5rem;
    font-size: 2rem;
    line-height: 2.8rem;
    color: $color--fuscous-gray;

    span {
      text-transform: uppercase;
    }
  }

  .node--page--body {
    margin-top: 1rem;


    p {
      margin: 0;
      @include text-clamp();

      @media #{$normal} {
        font-size: 2rem;
        line-height: 2.8rem;
      }
    }

    p:not(:first-of-type) {
      display: none;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }
}