@import 'assets/variables';
@import 'assets/_icons';

.SearchMembers {
  width: 100%;

  &__pagination {
    background: $color--white;
  }

  .SearchMembers__searchbar__wrapper {
    margin-bottom: 2rem;
  }

  .col-lg-7 {
    padding-left: 0;
    padding-right: 0;

    @media #{$tablet} {
      padding-right: auto;
    }
  }

  label {
    text-transform: uppercase;
  }

  input {
    color: $color--primary;
    width: 100%;
    padding: .8rem 1rem;
    padding-left: 4rem;
    font-size: 1.4rem;
    line-height: 2rem;
    border: solid 2px $color--calypso-lighter;
    border-radius: 0;

    &:placeholder-shown {
      color: $color--primary-lighter;
    }

    &:focus {
      outline: none;
    }

    &:before {
      @include icon;
      background-position: -4em 0;
    }
  }

  &__searchbar {
    &__icon {
      display: inline-block;
      position: absolute;
      line-height: 3.8rem;
      left: 3rem;
    }

    .PageHeader__content__searchbar__icon {
      display: none;
    }

    input {
      height: 4.1rem;
    }
  }

  .SearchMembers__filters {
    background-color: $color--border;
    padding: 1.5rem;

    @media #{$tablet} {
      height: 10rem;
    }

    .row {
      margin: 0;
    }
  }

  .SearchBar {
    &__submit {
      display: none;
    }
  }

  .Searchkit__Results {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-bottom: 0;
      width: 100%;
      padding-left: 0;
      border-bottom: 2px solid $color--border;

      @media #{$tablet} {
        border-right: 2px solid $color--border;
      }

      @media #{$tablet} {
        width: 50%;
      }

      @media #{$normal} {
        width: 33.333%;
      }

      &::before {
        display: none;
      }
    }
  }
}