@import 'assets/variables';
@import 'assets/mixins';

.NodeDossier {
  height: 100%;
  background-color: $color--white;

  &__infoheading {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    @media #{$tablet} {
      // This, because of absolute positioning on the breadcrumbs.
      padding-top: 8rem;
    }

    @media #{$normal} {
      gap: 1.5rem;
      padding-top: 11rem;
    }

    .wrapper {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 1rem;

      @media #{$tablet-down} {
        margin-right: 1.5rem;
      }

      .name {
        line-height: 2rem;
      }
    }

    .effect_date {
      margin-bottom: 1rem;
    }
  }

  &__title {
    margin-bottom: 2rem;

    @media #{$normal} {
      margin-bottom: 4rem;
    }
  }

  &__body {
    margin-bottom: 3rem;
    font-family: $font--medium;
    font-weight: 700;

    @media #{$normal} {
      margin-bottom: 4rem;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  &__timeline {
    margin-bottom: 3rem;

    @media #{$normal} {
      margin-bottom: 6rem;
    }

    .timeline__header {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.4rem;

      .timeline__title {
        text-transform: uppercase;
        font-family: $font--medium;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-right: 1rem;
      }

      .timeline__icon {
        display: flex;
        align-items: center;

        .icon {
          font-size: 2.2rem;
        }

        &:hover {
          .icon-info {
            background-position-y: 0em;
          }
        }
      }
    }

    .Steps {
      .step {
        display: flex;
        flex-direction: row;
        align-items: center;

        .line {
          display: flex;
          flex-direction: column;
          position: relative;
          align-items: center;
          height: 4rem;
          width: 2rem;
          margin-right: 1rem;

          .top {
            height: 50%;
            width: 0.2rem;
          }

          .dot {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 0.8rem;
            width: 0.8rem;
          }

          .btm {
            height: 50%;
            width: 0.2rem;
          }

          &.beforeCurrentStep {

            .top,
            .dot,
            .btm {
              background: $color--calypso;
            }
          }

          &.currentStep {
            .top {
              background: $color--calypso;
            }

            .dot {
              height: 1rem;
              width: 1rem;
              background: $color--white;
              border: 0.2rem solid $color--calypso;
            }

            .btm {
              background: $color--mystic;
            }
          }

          &.afterCurrentStep {

            .top,
            .dot,
            .btm {
              background: $color--mystic;
            }

            .dot {
              background: $color--white;
              border: 0.2rem solid $color--mystic;
            }
          }

          &.firstStep {
            .top {
              background: none;
            }
          }

          &.lastStep {
            .btm {
              background: none;
            }
          }
        }

        .label {
          display: flex;
          flex-direction: row;

          .text {
            display: flex;
            align-items: center;
            color: $color--calypso;
            font-family: $font--medium;
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0.8rem 0.8rem 0.8rem 0.5rem;
          }

          &:before {
            content: '';
            width: 1rem;
            height: 3.2rem;
          }

          &.currentStep {
            &:before {
              background: $color--black-squeeze;
              clip-path: polygon(0 50%, 100% 100%, 100% 0);
            }

            .text {
              background: $color--black-squeeze;
            }
          }

          &.afterCurrentStep {
            .text {
              color: $color--fuscous-gray;
            }
          }
        }
      }
    }
  }

  &__experts {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 6rem;

    @media #{$tablet} {
      flex-direction: row;
      margin-bottom: 12rem;
    }

    .wrapper {
      border: 0.2rem solid $color--border;

      @media #{$tablet} {
        max-width: 33.3%;
      }
    }
  }
}
