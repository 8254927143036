@import 'assets/variables';
@import 'assets/mixins';

.ParagraphIframe {
  &__wrapper {
    width: 100%;
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
