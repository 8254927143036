@import 'assets/variables';
@import 'assets/mixins';
@import 'assets/_icons';

body {
  @include font-size($font-size--body);
  @include font--light();
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $color--fuscous-gray;
  font-weight: normal;
  line-height: $line-height--body;

  @media #{$normal} {
    @include font-size($desktop--font-size--body);
    line-height: $desktop--line-height--body;
  }
}

a {
  color: $color--steel-blue;
  text-decoration: underline;

  &:hover {
    color: $color--fuscous-gray;
  }

  &:focus {
    color: $color--primary;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: $color--primary;
  font-weight: 900;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

h1,
.asH1 {
  @include h1();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}

h4,
.asH4 {
  @include h4();
}

h5,
.asH5 {
  @include h5();
}

.body-bold {
  @include body--bold;
}

.body-small {
  @include body--small;
}

.App {
  ul {
    padding-inline-start: 0;
    margin-bottom: 0;

    li {
      list-style: none;
      position: relative;
      padding-left: 3.4rem;
      margin-bottom: 1rem;

      &:before {
        @include icon;
        display: inline-block;
        vertical-align: middle;
        font-size: 100%;
        text-indent: -9999px;
        overflow: hidden;
        height: 1em;
        width: 1em;
        content: '';
        background-position: -11em -1em;
        position: absolute;
        left: 0;
        @include font-size(24);
      }
    }
  }

  ol {
    padding-inline-start: 0;
    counter-reset: list-counter;
    list-style: none;
    margin-bottom: 0;


    @for $index from 2 through 99 {
      &[start="#{$index}"] {
        counter-reset: list-counter ($index - 1);
      }
    }

    li {
      list-style: none;
      position: relative;
      padding-left: 3.4rem;
      margin-bottom: 1rem;
      counter-increment: list-counter;

      &:before {
        content: counter(list-counter);
        @include font--secondary();
        position: absolute;
        font-size: 16px;
        color: $color--calypso;
        left: 8px;
      }
    }
  }

  .body--large {
    @include body--large();
  }

}

.Button,
.Button-link a {
  border-radius: 0;
  position: relative;

  &:before,
  &:after {
    @include icon;
    display: inline-block;
    vertical-align: middle;
    font-size: 100%;
    text-indent: -9999px;
    overflow: hidden;
    height: 1em;
    width: 1em;
    content: '';
    background-position: -4em 0;
    position: absolute;
    right: 2rem;
    top: 1rem;
    @include font-size(22);
  }

  &--primary,
  &.NavigationFooterMenu__item__link {
    background-color: $color--kournikova;
    color: $color--fuscous-gray;
    @include font--secondary();
    padding: 1rem 4.7rem 1rem 2.5rem;
    text-transform: uppercase;
    line-height: 1.38;
    @include font-size(16);
    border: none;
    transition: background-color .1s linear, color .1s linear;

    &:after {
      opacity: 0;
      background-position: -4em -3em;
      transition: opacity .1s linear;
    }

    &:hover,
    &:focus {
      background-color: $color--fuscous-gray;
      color: white;
      text-decoration: none;

      &:after {
        opacity: 1;
        transition: opacity .1s linear;
      }
    }
  }

  &--secondary {
    background-color: $color--calypso;
    color: $color--white;
    @include font--secondary();
    padding: 1rem 4.7rem 1rem 2.5rem;
    text-transform: uppercase;
    line-height: 1.38;
    @include font-size(16);
    transition: background-color .1s linear, color .1s linear;

    &:before {
      background-position: -4em -3em;
    }

    &:after {
      opacity: 0;
      background-position: -4em 0;
      transition: opacity .1s linear;
    }

    &:hover,
    &:focus {
      background-color: $color--kournikova;
      color: $color--fuscous-gray;
      text-decoration: none;

      &:after {
        opacity: 1;
        transition: opacity .1s linear;
      }
    }
  }

  &--tertiary {
    color: $color--steel-blue;
    @include font--secondary();
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 0.5rem;

    &:before {
      all: unset;
    }

    &:after {
      position: unset;
      top: unset;
      right: unset;
      background-position-y: -2em;
    }

    &:hover {
      color: $color--steel-blue;
      text-decoration: none;

      &:after {
        background-position-y: -0;
      }
    }
  }

  &.Button--block {
    width: 100%;
    padding: 1rem;
  }

  &--inverted {
    padding: 0;
    background-color: transparent;
    border: none;
    @include font--secondary();
    color: $color--steel-blue;

    &:before,
    &:after {
      position: absolute;
      right: -25px;
      top: 0;
      margin-left: .5rem;
    }

    &:before {
      background-position-y: -2em !important;
    }

    &:after {
      background-position-y: 0 !important;
    }


    &:hover,
    &:focus {
      background-color: transparent;
      color: $color--fuscous-gray;
      text-decoration: none;
    }
  }

  &--mail {
    &:hover {
      color: $color--white !important;
      transition: background-color .1s linear, color .1s linear;

      &:after {
        background-position-y: -3em;
      }
    }

    &:before,
    &:after {
      background-position-x: -5em;
    }
  }

  &--home {
    &:before,
    &:after {
      background-position-x: 0;
    }
  }

  &--star {
    &:hover {
      background: #DB733A;
      color: $color--white !important;
      transition: background-color .1s linear, color .1s linear;

      &:after {
        background-position-y: -3em;
      }
    }

    &:before,
    &:after {
      top: 1rem;
      background-position-x: -11em;
    }
  }

  &--external {
    &.Button--primary {
      &:before {
        background-position: -17em 0;
      }

      &:after {
        background-position: -17em -3em;
      }

      &:hover,
      &:focus {
        background-color: $color--fuscous-gray;
        color: $color--white;
        text-decoration: none;
      }
    }

    &--star {
      padding-top: 1rem;
      padding-bottom: 1rem;
      line-height: 1.38;

      &:hover {
        background: #DB733A;
        color: $color--white;
        transition: background-color .1s linear, color .1s linear;

        &:after {
          background-position: -17em -3em;
        }
      }

      &:before,
      &:after {
        top: 1rem;
        background-position-x: -11em;
      }
    }

    &.Button--inverted {
      &:hover,
      &:focus {
        &:after {
          background-position: -17em 0;
        }
      }
    }

    &:before {
      background-position: -17em -3em;
    }

    &:after {
      background-position: -17em 0;
    }


    &:hover,
    &:focus {
      &:after {
        background-position: -17em -3em;
      }
    }
  }
}
