@import 'assets/variables';
@import 'assets/mixins';

.DownloadsPillarOverview {
  width: 100%;
  max-width: 1240px;
  border-bottom: 2px solid $color--mystic;

  > .row {
    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }
  }

  &__item-wrapper {
    border-bottom: 2px solid $color--border;
    @media #{$tablet} {
      border-bottom: none;

      .OverviewIntro__content,
      .DownloadTeaser__content {
        box-shadow: inset -2px 0 $color--border;
      }
    }

    &:last-child {
      border-bottom: none;

      .OverviewIntro__content,
      .DownloadTeaser__content {
        box-shadow: none;
      }
    }
  }
}
