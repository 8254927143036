@import "assets/variables";
@import "assets/mixins";

.GroupedContentFull {
  height: 100%;
  position: relative;
  background: $color--white;

  &__contentWrapper {
    h1 {
      margin-bottom: 2rem;

      @media #{$tablet} {
        font-size: 28px;
        line-height: 30px;
      }

      @media #{$normal} {
        font-size: 44px;
        line-height: 48px;
        margin-bottom: 4rem;
      }
    }
  }

  &__intro {
    @include body--bold();
  }

  &__author {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    
    @media #{$normal} {
      margin-top: 4rem;
    }

    &__image {
      img {
        border: .2rem solid $color--white;
        border-radius: 100%;
        object-fit: cover;
        height: 6rem;
        width: 6rem;
      }
    }

    &__details {
      margin-left: 3rem;

      &__name {
        font-size: 1.6rem;
        font-weight: 900;
      }
    }
  }

  &__content {
    padding-top: 4rem;
    padding-bottom: 3rem;

    @media #{$tablet} {
      padding-top: 9rem;
      padding-bottom: 6rem;
    }

    @media #{$normal} {
      padding-top: 12rem;
    }
  }

  .node--page--body {
    margin-top: 1rem;

    p {
      margin: 0;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }

  .node--page--paragraphs-wrapper {
    display: inline-block;
    width: 100%;
  }

  .node--page--dossiers-wrapper {
    >.DossierTeaser {
      border-bottom: 2px solid $color--mystic;
    }
  }

  .TitleHeaderBlock {
    display: none;
  }

  &.display-related-block .TitleHeaderBlock {
    display: block;
  }
}