@import "assets/variables";
@import "assets/mixins";

.StaffFull {
  height: 100%;
  position: relative;
  background: $color--white;

  &__intro {
    @include body--bold();
    margin-top: 2rem;

    @media #{$normal} {
      margin-top: 3rem;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    margin-top: 2rem;

    @media #{$normal} {
      margin-top: 3rem;
      gap: 1.5rem;
    }
  }

  &__content {
    padding-bottom: 6rem;

    @media #{$tablet} {
      padding-top: 9rem;
    }

    @media #{$normal} {
      padding-top: 12rem;
    }
  }

  &__function {
    @include body--large();
  }

  &__name {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media #{$tablet} {
      gap: 0.5rem;
    }

    h1 {
      margin-bottom: 0;
    }

    @media #{$tablet} {
      padding: 3rem;
      height: 15rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__image {
    height: 15rem;
    width: 15rem;
    border-radius: 100%;
    margin-bottom: 2rem;
    background-color: $color--black-squeeze;

    @media #{$tablet} {
      float: left;
      margin-bottom: 0;
    }
  }

  .node--page--body {
    margin-top: 1rem;

    p {
      margin: 0;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }

  .node--page--paragraphs-wrapper {
    display: inline-block;
    width: 100%;
  }
}
