@import 'assets/variables';
@import 'assets/mixins';

.SliderRelated {
  &__next,
  &__prev {
    top: 0;
    bottom: 0;
    width: 6rem;
    z-index: 9;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    transition: background-color 0.33s linear;
    background-color: rgba($color--kournikova, 0);

    &:after {
      background-color: transparent;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transition: background-color 0.33s linear;
    }

    &:hover {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #e5eaec 100%);

      &:after {
        background-color: $color--kournikova;
        transition: background-color 0.33s linear;
      }

      .icon-before {
        opacity: 0;
        transition: opacity .1s linear;
      }
    }

    .icon-after,
    .icon-before {
      font-size: 30px;
      position: absolute;
      left: 3rem;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .icon-before {
      z-index: 2;
      opacity: 1;
      transition: opacity .1s linear;
    }

    .icon-after {
      z-index: 1;
    }
  }

  &__next {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #e5eaec 100%);

    @media #{$normal} {
      opacity: 0;
      transition: opacity .1s linear, background-color 0.33s linear;
      pointer-events: none;
    }
  }

  &__prev {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #e5eaec 100%);

    @media #{$normal} {
      opacity: 0;
      transition: opacity .1s linear, background-color 0.33s linear;
      pointer-events: none;
    }

    &--visible {
      opacity: 0;
      transition: opacity .1s linear, background-color 0.33s linear;
      pointer-events: all;
    }
  }
}
