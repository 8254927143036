@import 'assets/mixins';
@import 'assets/grid';

.App {
  position: relative;
  font-display: swap;

  &--container {
    padding: 0;
  }

  .main {
    margin-top: 6rem;

    @media #{$normal} {
      margin-top: 0;
      margin-left: 29rem;
    }
  }

  .cookie_compliance_popup {
    &__inner_wrapper {
      background: $color--elephant;
      font-size: 1.6rem;
      line-height: 1.5;
      color: $color--white;
      border-radius: 0;
      box-shadow: none;
    }

    p {
      color: inherit;
      display: inline-block;
      margin: 0;

      a {
        color: $color--white;
        text-decoration: underline;

        &:hover {
          color: $color--kournikova;
        }
      }

    }

    .cookie_compliance_popup__content_container {

      @media #{$mobile-only} {
        padding: 1rem;
      }

      padding: 1.5rem 3rem;

      border-right: 1px solid rgba($color: #FFF, $alpha: .3);

      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    .cookie_compliance_popup__button--agree {
      border-bottom: 1px solid rgba($color: #FFF, $alpha: .3);
    }

    .cookie_compliance_popup__button {
      font-size: 1.6rem;
      @include font--secondary();
      line-height: 1.38;

      &:hover {
        color: $color--kournikova;
      }
    }
  }

}