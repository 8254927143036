@import 'assets/variables';
@import 'assets/mixins';

.SearchResult {
  border-bottom: 2px solid #c2d2de;
  padding: 3rem 2rem;
  position: relative;
  transition: background-color 0.33s linear;
  cursor: pointer;

  &:hover,
  &:focus {
    transition: background-color 0.33s linear;
    background-color: $color--kournikova;
  }

  @media #{$tablet} {
    padding: 3rem;
  }

  &__type {
    text-transform: uppercase;
  }

  &__body {
    overflow: hidden;
    text-overflow: ellipsis;
    display: box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__type,
  &__date,
  &__body,
  &__url {
    @include font-size(16);
    line-height: 1.5;
    color: #4e4e4c;
  }

  &__title {
    @include font-size(18);
    @include font--secondary();
    line-height: 1.11;
    letter-spacing: -0.5px;
    color: #4e4e4c;
    margin-bottom: 1rem;
  }
}
