@import 'assets/variables';
@import 'assets/mixins';

.App .CaoPdfLinks--Overview {
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    padding-top: 1rem;
    background-color: $color--white;
  }

  &__list-item {
    padding: 0;
    margin: 0;

    &:before {
      display: none;
    }

    a {
      @include body--bold;

      display: flex;
      column-gap: 2rem;
      color: $color--jaffa;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }

      &.icon-before:before {
        @include font-size(24);

        flex: 0 0 auto;
      }
    }
  }
}
