// -----------------------------------------------------------------------------
//
// Grid utilities
//
// -----------------------------------------------------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 850px,
  lg: 1240px,
  xl: 1440px,
);

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1440px,
);

$grid-gutter-width-base: 30px;
$grid-xs-gutter-width-base: 30px;
$grid-sm-gutter-width-base: 30px;
$grid-md-gutter-width-base: 30px;
$grid-lg-gutter-width-base: 30px;
$grid-xl-gutter-width-base: 30px;

$grid-gutter-widths: (
  xs: $grid-xs-gutter-width-base,
  sm: $grid-sm-gutter-width-base,
  md: $grid-md-gutter-width-base,
  lg: $grid-lg-gutter-width-base,
  xl: $grid-xl-gutter-width-base,
);

$screen-sm: #{map-get($grid-breakpoints, sm)};
$screen-md: #{map-get($grid-breakpoints, md)};
$screen-lg: #{map-get($grid-breakpoints, lg)};
$screen-xl: #{map-get($grid-breakpoints, xl)};

$screen-phone: $screen-sm;
$screen-tablet: $screen-md;
$screen-desktop: $screen-lg;
$screen-lg-desktop: $screen-xl;

$screen-xs-max: #{map-get($grid-breakpoints, sm) - 1};
$screen-sm-max: #{map-get($grid-breakpoints, md) - 1};
$screen-md-max: #{map-get($grid-breakpoints, lg) - 1};
$screen-lg-max: #{map-get($grid-breakpoints, xl) - 1};

// Lazy media query variables.
$mobile: 'screen and (min-width: #{$screen-sm})';
$tablet: 'screen and (min-width: #{$screen-md})';
$normal: 'screen and (min-width: #{$screen-lg})';
$wide: 'screen and (min-width: #{$screen-xl})';
$mobile-only: 'screen and (max-width: #{$screen-sm-max})';
$tablet-only: 'screen and (min-width: #{$screen-md}) and (max-width: #{$screen-md-max})';
$normal-only: 'screen and (min-width: #{$screen-lg}) and (max-width: #{$screen-lg-max})';
$mobile-down: 'screen and (max-width: #{$screen-sm-max})';
$tablet-down: 'screen and (max-width: #{$screen-md-max})';
$normal-down: 'screen and (max-width: #{$screen-lg-max})';

// -----------------------------------------------------------------------------
//
// Spacing
//
// -----------------------------------------------------------------------------

$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: 8px,
    2: 16px,
    3: 24px,
    4: 32px,
    5: 40px,
    6: 48px,
    7: 56px,
    8: 64px,
    9: 72px,
  ),
  $spacers
);

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

// Primary fonts will be used for 90% of the text styles, mainly body text.
// Secondary fonts will be used mainly for headings.
$font--primary: "Helvetica Neue Light", Helvetica, Arial, sans-serif;
$font--secondary: "Helvetica Neue Bold", Helvetica, Arial, sans-serif;
$font--tertiary: "Helvetica Neue Bold", Helvetica, Arial, sans-serif;
$font--light: "Helvetica Neue Light", Helvetica, Arial, sans-serif;
$font--medium: "Helvetica Neue", Helvetica, Arial, sans-serif;

// DESKTOP: Font sizes (in pixels) / line-heights
$desktop--font-size--h1: 44;
$desktop--line-height--h1: 48px;

$desktop--font-size--h2: 30;
$desktop--line-height--h2: 34px;

$desktop--font-size--h3: 20;
$desktop--line-height--h3: 24px;

$desktop--font-size--h4: 14;
$desktop--line-height--h4: 16px;

$desktop--font-size--h5: 14;
$desktop--line-height--h5: 16px;

$desktop--font-size--body--large: 20;
$desktop--line-height--body--large: 28px;

$desktop--font-size--body: 16;
$desktop--line-height--body: 24px;

$desktop--font-size--body--small: 16;
$desktop--line-height--body--small: 24px;

$desktop--font-size--button: 18;
$desktop--line-height--button: 30px;

// TABLET: Font sizes (in pixels) / line-heights
$tablet--font-size--h1: 28;
$tablet--line-height--h1: 30px;

$tablet--font-size--h2: 22;
$tablet--line-height--h2: 24px;

$tablet--font-size--h3: 18;
$tablet--line-height--h3: 20px;

$tablet--font-size--h4: 14;
$tablet--line-height--h4: 16px;

$tablet--font-size--h5: 14;
$tablet--line-height--h5: 16px;

$tablet--font-size--body--large: 20;
$tablet--line-height--body--large: 28px;

$tablet--font-size--body: 16;
$tablet--line-height--body: 24px;

$tablet--font-size--body--small: 16;
$tablet--line-height--body--small: 24px;

$tablet--font-size--button: 16;
$tablet--line-height--button: 28px;

// MOBILE: Font sizes (in pixels) / line-heights
$font-size--h1: 28;
$line-height--h1: 30px;

$font-size--h2: 22;
$line-height--h2: 24px;

$font-size--h3: 18;
$line-height--h3: 20px;

$font-size--h4: 12;
$line-height--h4: 14px;

$font-size--h5: 12;
$line-height--h5: 14px;

$font-size--body--large: 20;
$line-height--body--large: 30px;

$font-size--body: 16;
$line-height--body: 24px;

$font-size--body--small: 14;
$line-height--body--small: 26px;

$font-size--button: 16;
$line-height--button: 28px;


// -----------------------------------------------------------------------------
//
// Colors
//
// -----------------------------------------------------------------------------
$color--fuscous-gray: #4E4E4C;
$color--calypso: #356A93;
$color--calypso-lighter: #9AB4C9;
$color--kournikova: #FFE37E;
$color--light-yellow: #FFF9E5;
$color--yelow: #FFF6D6;
$color--porcelain: #e5eaec;
$color--elephant: #133248;
$color--steel-blue: #4081b3;
$color--jaffa: #DB733A;
$color--rose-white: #FFEDE4;

$color--black-squeeze: #ebf2f7;
$color--black-squeeze-lighter: rgba($color--black-squeeze, .3);
$color--mystic: #d7e1e9;

$color--primary: $color--fuscous-gray;
$color--primary-lighter: rgba($color--fuscous-gray, .6);
$color--secondary: $color--elephant;

$color--blue--dark: #141331;
$color--black: #141331;
$color--grey: #4A4952;
$color--white: #FFF;

$color--disabled: #D8D7E2;
$color--text: #777;

$color--transparent: rgba(255, 255, 255, 0);
$color--placeholder: #f0f0f0;
$color--error: #da3636;


$color--faq-background: #f2f2ff;

// -----------------------------------------------------------------------------
//
// Borders
//
// -----------------------------------------------------------------------------

$color--border: #d7e1e9;
$border-radius--primary: 4px;
$border-radius--secondary: 8px;

// -----------------------------------------------------------------------------
//
// Shadows
//
// -----------------------------------------------------------------------------
$box-shadow--primary: 0 8px 10px 0 rgba(77, 73, 255, 0.08);
$box-shadow--secondary: 0 30px 30px 0 rgba(77, 73, 255, 0.08);
$box-shadow--tertiary: 0 4px 5px 0 rgba(77, 73, 255, 0.16);

// -----------------------------------------------------------------------------
//
// z-index
//
// -----------------------------------------------------------------------------
$z-index-0: 0;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 100;
$z-index-5: 1000;

// -----------------------------------------------------------------------------
//
// Animation
//
// -----------------------------------------------------------------------------
$animation-timing--cubic-bezier: cubic-bezier(0.23, 0, 0.03, 1.01);
