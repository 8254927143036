@import 'assets/variables';
@import 'assets/mixins';

.LandingPage {
  width: 100%;

  .PageHeader {
    h1 {
      font-size: 2.8rem;
      line-height: 3rem;

      @media #{$normal} {
        font-size: 4.4rem;
        line-height: 4.8rem;
      }
    }
  }

  .sub-title {
    text-transform: initial;
    padding-top: 1rem;
    font-size: 2rem;
    max-width: 50rem;
  }

  > .Container {
    margin: 4rem auto;

    @media #{$tablet} {
      margin: 6rem auto;
    }
  }

  .node--page--paragraphs-wrapper {
    background-color: $color--white;
    width: 100%;
  }
}
