@import 'assets/variables';
@import 'assets/mixins';

.PillarsHomeOverview {
  .pillar__teaser-container {
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid $color--black-squeeze;
    border-bottom: 1px solid $color--black-squeeze;

    @media #{$tablet} {
      border-top: none;
      border-bottom: none;
      border-left: 1px solid $color--black-squeeze;
      border-right: 1px solid $color--black-squeeze;
    }

    &:first-child {
      border-top-color: $color--mystic;
      @media #{$tablet} {
        border-left-color: $color--mystic;
      }
    }

    &:last-child {
      border-bottom-color: $color--mystic;
      @media #{$tablet} {
        border-right-color: $color--mystic;
      }
    }
  }
}
