@import 'assets/mixins';
@import 'assets/grid';

.NavigationVertical {
  position: absolute;
  z-index: 101;
  display: flex;
  justify-content: center;
  left: 0;
  right: auto;
  top: 30%;
  transform: translateY(-50%);
  padding: 0 2rem;
  align-items: center;
  opacity: 1;
  transition: opacity .5s linear, right .25s linear;

  @media #{$tablet} {
    left: 9rem;
  }

  @media #{$normal} {
    left: 6rem;
    top: 50%;
  }

  &--hidden {
    opacity: 0;
  }

  &__wrapper {
    &__container {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    margin: 0.5rem 0;
    cursor: pointer;

    &__title {
      position: absolute;
      left: 200%;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 1rem;
      background-color: black;
      padding: 0.2rem 0.5rem;
      color: white;
      white-space: nowrap;
      opacity: 0;
      transition: opacity 0.2s linear;

      display: none;
      @media #{$tablet} {
        display: block;
      }
    }

    &:hover &__title {
      opacity: 1;
    }

    &__icon {
      display: block;
      position: relative;
      background-color: #fefefe;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
      border-radius: 0;
      opacity: 0.6;
      margin: 0;
      width: 0.8rem;
      height: 0.8rem;
    }

    &.active &__icon,
    &:hover &__icon {
      opacity: 1;
    }
  }

}
