@import 'assets/variables';
@import 'assets/mixins';

.ParagraphQuote {
padding-bottom: 3rem;

@media #{$tablet} {
  padding-bottom: 6rem;
}

  &__wrapper {
    display: flex;
  }

  .icon {
    @include font-size(30);
    margin-right: 1.5rem;
  }

  &__quote {
    font-size: 20px;
    @include font--secondary();
    line-height: 1.2;
    color: $color--fuscous-gray;
    margin-bottom: 1rem;
  }

  &__author {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: $color--fuscous-gray;
  }
}
