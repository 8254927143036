@use 'sass:map';

@import 'assets/variables';
@import 'assets/mixins';

.ParagraphWebform {
  margin-bottom: 3rem;

  @media #{$normal} {
    margin-bottom: 12rem;
  }
}
