@import 'assets/variables';
@import 'assets/mixins';

.TaxonomyTermPillarFull {
  .PageHeader {
    @media #{$tablet-down} {
      height: auto;

      .content {
        padding-bottom: 0;
      }
    }
    @media #{$tablet} {
      padding-top: 6rem;
    }

    h1 {
      text-transform: uppercase;

      .icon {
        margin-top: -10px;
        @include font-size(30);

        @media #{$normal} {
          @include font-size(48);
        }
      }
    }

    .body-intro {
      @media #{$tablet} {
        margin-bottom: 3rem;

        p:last-child {
          margin-bottom: 0;
        }
      }
      @media #{$normal} {
        margin-bottom: 0;
      }
    }
  }

  .View__filters {
    display: none;
  }

  &__imageWrapper {
    margin: 0 -1rem;
    margin-top: -1rem;
    @media #{$tablet} {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 0;
    }

    img {
      width: 100%;
      @media #{$tablet} {
        width: auto;
        height: 100%;
      }
    }
  }

  .body-intro {
    max-width: 57.5rem;
  }

  .node--page--paragraphs-wrapper {
    display: inline-block;
    width: 100%;
  }
}
