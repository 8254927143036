@import 'assets/variables';
@import 'assets/mixins';

.PagerPrevNext {
  padding: 1.7rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  background-color: $color--white;

  @media #{$normal} {
    padding: 3.2rem 2rem;
  }

  .flex-fill {
    flex: 1 0 auto;
  }

  &__icon {
    &:last-child {
      margin: 0;
    }
  }

  &__button {
    cursor: pointer;

    &:hover {
      span {
        color: $color--fuscous-gray;
      }

      .icon-before:before {
        background-position-y: 0;
      }
    }

    span {
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color--steel-blue;
      font-family: $font--secondary;
      text-transform: uppercase;
    }

    span + .icon-before,
    .icon-before + span {
      margin-left: .5rem;
    }

    .icon-before {
      display: inline-block;
      font-size: 2.2rem;
      line-height: 1.9rem;
    }
  }
}
