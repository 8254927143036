@import 'assets/variables';
@import 'assets/mixins';

.ArticleTeaser, .NewsTeaser {
  background-color: $color--white;
  transition: background-color 0.33s linear;
  position: relative;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  @media #{$tablet} {
    display: flex;
    flex-direction: row;
  }

  h3 {
    margin-bottom: 1rem;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
      padding-top: 133.33%;
    }
  }

  &__content {
    padding: 3rem 2rem 1rem;

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    @media #{$tablet} {
      padding: 0;
      display: flex;
      flex-direction: column;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__imageWrapper {
    margin-left: 1.5rem;

    @media #{$tablet} {
      width: 100%;
      margin-left: 0;
    }

    img {
      width: 8rem;
      height: auto;

      @media #{$tablet} {
        width: 100%;
      }
    }
  }

  &__imageNormal {
    display: none;
    height: 100%;
    @media #{$tablet} {
      display: block !important;
    }
  }

  &__imageMobile {
    @media #{$tablet} {
      display: none !important;
    }
  }

  &__contentWrapper {
    flex: 1 1 auto;
    padding: 0;
    display: block;
    text-align: left;

    @media #{$tablet} {
      padding: 2rem;
    }

    @media #{$normal} {
      padding: 3rem;
    }
  }
  
  &__date {
    margin-top: .7rem;
    
    @media #{$tablet} {
      margin-top: 4.5rem;
    }
  }

  .node--sub-heading {
    font-size: 1.2rem;

    span {
      letter-spacing: 0.5px;
    }
  
    .pinned {
      font-weight: 700;
      color: $color--jaffa;

      .icon {
        margin-bottom: 2px;
      }
    }
  }


  .node--page--body, .node--page--body p {
    margin-top: 1rem;
    @include text-clamp(2);

    @media #{$tablet} {
      @include text-clamp(3);
    }
  }

  .node--page--body p {
    margin: 0;
  }

  .node--page--body p:not(:first-of-type) {
    display: none;
  }

  .overlapping-link:before {
    z-index: 1;
  }
}
