@import 'assets/variables';
@import 'assets/mixins';

.PageHeader {
  width: 100%;
  min-height: 29rem;
  background-color: #d8e1e8;
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;

  &.with-filter {
    min-height: 19rem;
  }

  @media #{$tablet} {
    height: 29rem;

    &.with-filter {
      height: 19rem;
    }
  }

  .is-front-page & {
    background-color: $color--elephant;

    h1 {
      color: $color--white;
    }
  }

  .content {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: stretch;
    align-items: flex-start;
    padding: 3rem 2rem 2rem;
    text-transform: uppercase;

    @media #{$tablet} {
      padding-top: 0;
    }

    @media #{$normal} {
      padding: 3rem;
      padding-bottom: 2.5rem;
    }

    .body-intro {
      margin-top: 1.5rem;
      font-size: 1.8rem;
      line-height: 2.6rem;
      color: $color--primary;
    }
  }
}
