@import 'assets/variables';
@import 'assets/mixins';

.pager {
  margin: 1.5rem 0;

  @media #{$tablet} {
    margin: 3rem 0;
  }

  ul.pager__list,
  ul.pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    li {
      margin: 0;
      padding: 0;

      &:before {
        content: none;
      }

      &:active {
        outline: none;
      }

      &.previous {
        left: 3rem;

        a {
          display: flex;
          align-items: center;
        }
      }

      &.next {
        right: 3rem;

        a {
          display: flex;
          align-items: center;
        }
      }

      a {
        min-width: 3rem;
        height: 3rem;
        display: block;
        text-align: center;
        font-size: 1.6rem;
        line-height: 3rem;
        font-weight: 700;
        color: $color--steel-blue;
        cursor: pointer;
        font-family: $font--secondary;

        &:active,
        &:focus {
          outline: none;
        }
      }

      &.previous,
      &.next {
        position: absolute;

        span {
          display: none;
          font-weight: 700;
          text-transform: uppercase;
          font-family: $font--secondary;

          @media #{$tablet} {
            display: inline;
          }
        }

        a:hover {
          background-color: transparent !important;

          .icon-caret {
            background-position-y: 0;
          }
        }

        &.disabled {
          span {
            color: #c2d2de;
          }

          .icon-caret {
            background-position-y: -4.4rem;
          }

          a:hover {
            .icon-caret {
              background-position-y: -4.4rem;
            }
          }
        }

      }

      .icon-caret {
        @include font-size(22);
      }

      &.active a {
        background-color: $color--steel-blue;
        color: $color--white;
      }

      a:hover {
        background-color: $color--kournikova;
        color: $color--primary;
      }
    }
  }
}
