@import 'assets/variables';
@import 'assets/mixins';

.ArticleTeaser {
  background-color: $color--white;
  transition: background-color 0.33s linear;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  @media #{$tablet} {
    display: flex;
    flex-direction: row;
  }

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
      padding-top: 133.33%;
    }
  }

  &__content {
    padding: 3rem 2rem;

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    @media #{$tablet} {
      padding: 0;
      display: flex;
      flex-direction: column;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__imageWrapper {
    margin-left: 1.5rem;

    @media #{$tablet} {
      width: 100%;
      margin-left: 0;
    }

    img {
      width: 8rem;
      height: auto;

      @media #{$tablet} {
        width: 100%;
      }
    }
  }

&__imageNormal {
    display: none;
    @media #{$tablet} {
      display: block !important;
    }
  }

  &__imageMobile {
    @media #{$tablet} {
      display: none !important;
    }
  }

  &__contentWrapper {
    flex: 1 1 auto;
    padding: 0;
    display: block;
    text-align: left;

    @media #{$tablet} {
      padding: 2rem;
    }

    @media #{$normal} {
      padding: 3rem;
    }
  }

  .node--sub-heading {
    margin-bottom: .5rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $color--primary;

    span {
      text-transform: uppercase;
    }
  }

  .node--page--body {
    margin-top: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @media screen and (min-width: 940px) {
      -webkit-line-clamp: 3;
    }

    p {
      margin: 0;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }
}
