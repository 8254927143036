@import 'assets/variables';
@import 'assets/mixins';

.GroupedContentDetailMore {
  overflow: auto;

  &__item-wrapper {
    border-bottom: 2px solid $color--border;
    @media #{$tablet} {
      border-right: 2px solid $color--border;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
