@import 'assets/variables';
@import 'assets/mixins';

.MemberTeaser {
  position: relative;
  overflow: hidden;

  background-color: $color--white;
  transition: background-color 0.33s linear;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 100%;

    @media #{$tablet} {
      padding-top: 133.33%;
    }

    @media #{$normal} {
      padding-top: 100%;
    }
  }

  &__website,
  &__email {
    a {
      color: $color--fuscous-gray;
      text-decoration: none;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 3rem 2rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    @media #{$tablet} {
      padding: 3rem;
    }

    h1 {
      margin-bottom: 1rem;

      @media #{$normal} {
        margin-bottom: 1.5rem;
      }
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    margin-bottom: 1rem;

    font-size: 1.6rem;
    line-height: 2.4rem;

    i {
      margin-right: 1rem;
      font-size: 2.4rem;
      line-height: 1.9rem;
    }

    &__content {
      span {
        display: block;
      }
    }
  }
}
