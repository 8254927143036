.overlapping-link {
  &:before {
   width: 100%;
    height:100%;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  ~ picture {
    z-index: -1;
  }
}

.uppercase {
  text-transform: uppercase;
}

.focus-ring {
  @include focus-ring;
}

.link-box {
  @include link-box;

  &--before {
    @include link-box('before');
  }
}

.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
