@import 'assets/variables';
@import 'assets/mixins';

.DownloadTeaserLarge {
  position: relative;
  background-color: $color--white;
  transition: background-color 0.33s linear;
  border-bottom: 2px solid $color--mystic;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 133.33333333333334%;

    @media #{$tablet} {
      padding-top: 33.33%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

    @media #{$tablet} {
      display: flex;
      flex-direction: row;
    }

    .reversed & {
      @media #{$tablet} {
        flex-direction: row-reverse;
      }
    }
  }

  &__imageWrapper {
    @media #{$tablet} {
      height: 100%;
      border-right: 2px solid $color--mystic;

      .reversed & {
        border-left: 2px solid $color--mystic;
        border-right: none;
      }
    }
  }

  &__imageNormal {
    width: auto;
    height: 100%;
    display: none;

    @media #{$tablet} {
      display: block;
    }
  }

  &__imageMobile {
    width: 100%;
    height: auto;
    @media #{$tablet} {
      display: none;
    }
  }

  &__contentWrapper {
    flex: 1 1 auto;
    padding: 3rem 2rem;
    display: block;
    text-align: left;

    @media #{$tablet} {
      height: 100%;
      padding: 3rem;
    }

    @media #{$normal} {
      padding: 6rem;
    }
  }

  .node--sub-heading {
    font-size: 1.6rem;
  }

  .node--page--body {
    margin-top: 1rem;

    p {
      margin: 0;
      @include text-clamp();

      @media #{$normal} {
        font-size: 2rem;
        line-height: 2.8rem;
      }
    }

    p:not(:first-of-type) {
      display: none;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }
}
