@import 'assets/variables';
@import 'assets/mixins';

.TaxonomyTermThemeFull {
  .PageHeader {
    h1 {
      text-transform: uppercase;
    }
  }

  &__filters {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    @media #{$tablet} {
      flex-direction: row;
    }

    > .View {
      margin-bottom: 1rem;

      @media #{$tablet} {
        margin-bottom: 0;
      }
    }

    .label {
      @include font--secondary();
      font-size: 1.2rem;
      line-height: 2rem;
      white-space: nowrap;
      margin-right: 1rem;
      margin-bottom: 0;
    }

    .input__tags {
      @media #{$tablet} {
        margin-left: 3rem;
      }
    }

    .Filter__tags {
      @media #{$tablet-down} {
        max-width: 100%;
        overflow: auto;
      }
    }
  }

  .body-intro {
    max-width: 50rem;
    @media #{$normal} {
      max-width: 50%;
    }
  }

  .node--page--paragraphs-wrapper {
    display: block;
    width: 100%;
  }
}
