@import 'assets/variables';
@import 'assets/mixins';

.FAQAccordionItem {
  background: $color--white;

  // Hover state
  &:hover {
    .Accordion__item__title {
      background-color: $color--kournikova;
    }
  }

  // Toggle icon when collapsed
  &.collapsed {
    .icon {
      background-position-y: -1em;
      opacity: 0.5;
      transform: rotate(90deg);
    }
  }

  // Active state
  &:not(&.collapsed) {
    .Accordion__item__title {
      background-color: $color--kournikova;
    }

    .icon {
      transform: rotate(270deg);
    }
  }

  // Item itself
  .Accordion__item, &.Accordion__item {
    border-bottom: solid 2px $color--border;

    &__title {
      button {
        padding: 3rem;

        @media #{$mobile-only} {
          padding: 2rem;
        }

        @media #{$tablet-down} {
          padding-right: 5rem;
        }
      }

      .icon--arrow-right {
        display: none;
      }

      i.icon {
        position: absolute;
        right: 2rem;
        top: 2rem;

        @media #{$tablet} {
          right: 3rem;
        top: 3rem;
        }
      }
    }
  }

  &__content {
    padding: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 2rem;

    @media #{$tablet} {
      padding: 1.5rem;
      padding-top: 1.5rem;
      padding-bottom: 3rem;
    }
  }

  .Text__title p {
    margin-bottom: 0;
  }
}
