@import 'assets/variables';
@import 'assets/mixins';

.NodeCourseFull {
  height: 100%;
  position: relative;
  background: $color--white;

  &__contentWrapper {
    padding-top: 6.5rem;

    @media #{$normal} {
      padding-top: 12rem;
    }

    .Button.external-icon:before {
      background-position: -17em 0;
    }

    .Button.external-icon:after {
      background-position: -17em -3em;
    }

    .node {
      &--sub-heading {
        font-size: 1.2rem;
        margin-bottom: 2rem;

        @media #{$normal} {
          margin-bottom: 4rem;
        }
      }

      &--courses-heading {
        margin-bottom: 1.5rem;

        @media #{$normal} {
          margin-bottom: 3rem;
        }
      }

      &--title {
        margin-bottom: 2rem;
        @media #{$tablet} {
          line-height: 3rem;
        }
        @media #{$normal} {
          margin-bottom: 4rem;
          line-height: 4.8rem;
        }
      }

      &--body {
        @include font--secondary();
        margin-bottom: 2rem;
        line-height: 2.4rem;

        @media #{$normal} {
          margin-bottom: 6rem;
        }
      }

      &--first-training {
        h3 {
          margin-bottom: 2rem;
        }

        .course-data {
          margin-top: 1rem;
          margin-bottom: 3.5rem;
          font-size: 1.4rem;

          &__row {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid $color--border;
            padding: 0.5rem 0;

            &__col {
              &.label {
                flex: 0 1 30%;
                @include font--secondary();
                text-transform: uppercase;

                @media #{$mobile-only} {
                  min-width: 14rem;
                }
              }

              &.value {
                flex: 1;
              }
            }
          }
        }

        .buttons {
          .Button {
            width: 100%;
            margin-bottom: 2rem;
            @media #{$tablet} {
              width: auto;
              margin-bottom: 0;
              margin-right: 3rem;
            }
          }

          .more-dates {

            color: $color--steel-blue;
            text-decoration: underline;

            &:hover {
              color: black;
              text-decoration: none;
            }
          }
        }
      }

      &--paragraphs-wrapper {
        margin: 6rem 0 3rem;
      }
    }

    .course-dates {
      margin-bottom: 0;

      @media #{$normal} {
        margin-bottom: 3rem;
      }

      .CoursesUpcomingDates {
        padding: 3rem 0;
      }
    }

    .TitleHeaderBlock {
      margin-top: 6rem;
      @media #{$normal} {
        margin-top: 12rem;
      }
    }
  }
}
