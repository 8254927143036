@import 'assets/mixins';
@import 'assets/grid';

.OverviewIntro {
  height: 100%;
  background-color: $color--white;
  position: relative;

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
    }
  }

  &--article {
    &:before {
      @media #{$tablet} {
        padding-top: 133.33%;
      }
    }
  }

  &--theme {
    &:before {
      @media #{$tablet} {
        padding-top: 133.33%;
      }
      @media #{$normal} {
        padding-top: 100%;
      }
    }
  }

  &__Button {
    display: flex;
    align-items: center;
  }

  &__content {
    padding: 3rem 2rem;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: flex-start;

    @media #{$tablet} {
      padding: 3rem;
      display: flex;
      flex-direction: column;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .Button.hidden-other {
      display: none;
    }

    .Button.hidden-break {
      @media only screen and (min-width: 850px) and (max-width: 1035px) {
          display: none;
      }
    }

    .Button.hidden-other {
      @media only screen and (min-width: 850px) and (max-width: 1035px) {
          display: block;
      }
    }

    h2 {
      text-transform: uppercase;
    }
  }

  &__contentWrapper {
    margin-bottom: 6rem;

    @media #{$tablet} {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 2rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
