@import 'assets/variables';
@import 'assets/mixins';

.GroupedContentThemeDetailPromoted {
  overflow: auto;

  &__item-wrapper {
    border-bottom: 2px solid $color--border;
    @media #{$tablet} {
      border-right: 2px solid $color--border;
    }
  }
}
