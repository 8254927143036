@import 'assets/variables';
@import 'assets/mixins';

.ParagraphText {

  margin-bottom: 3rem;

  @media #{$tablet} {
    margin-bottom: 6rem;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 1.5rem;
  }

  h4,
  h5 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;

    &:last-of-type:not(:first-of-type) {
      margin-bottom: 0;
    }

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5 {
      display: inline-block;
      margin-top: 1rem;
    }

  }

  &__button {
    margin-top: 2rem;
  }
}
