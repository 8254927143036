@import 'assets/variables.scss';

.InfoHeading {

  margin-bottom: 1.5rem;

  @media #{$normal} {
    margin-bottom: 2rem;
  }

  &__theme {
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  &__type {
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    margin-right: 1.5rem;
  }

  &__date {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5rem;
  }

  &__readtime {
    display: inline-block;
    vertical-align: middle;
  }

  .icon-divider {
    margin-right: 1.5rem;
  }
}

div.InfoHeading__theme {
  margin-right: 1.5rem;
}