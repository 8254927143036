@import 'assets/variables';
@import 'assets/mixins';

.Select__select {
  width: 100%;
  z-index: 3;

  @media #{$tablet} {
    width: 335px;
  }
  @media #{$normal} {
    width: 260px;
  }

  .Select__inner__value-container {
    padding: 0 1rem !important;
  }
}
