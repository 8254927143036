@import 'assets/variables';
@import 'assets/mixins';

.NewsPressReleasesOverview {
  overflow: auto;
  border-bottom: 2px solid $color--mystic;

  > .row {
    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }
  }

  &__item-wrapper {
    border-bottom: 2px solid $color--border;
    @media #{$tablet} {
      border-right: 2px solid $color--border;
    }
  }
}
