@import 'assets/variables';
@import 'assets/mixins';

.BlockCta {
  position: relative;
  background-color: $color--white;
  border-bottom: 2px solid $color--border;
  transition: background-color 0.33s linear;

  &.hasLink {

    &:hover,
    &:focus {
      background-color: $color--kournikova;
      transition: background-color 0.33s linear;
    }
  }

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
      padding-top: 33.33%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    @media #{$tablet} {
      display: flex;
      flex-direction: row;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .reversed & {
      @media #{$tablet} {
        flex-direction: row-reverse;
      }
    }
  }

  &__imageWrapper {
    height: 100%;

    @media #{$tablet} {
      border-left: 2px solid $color--border;

      .reversed & {
        border-right: 2px solid $color--border;
        border-left: none;
      }
    }

    @media #{$normal} {
      height: 100%;
      width: 33%;
      display: flex;
      flex: 1 0 33.4%;
    }
  }

  &__contentWrapper {

    flex: 1 1 auto;
    padding: 3rem 2rem;
    display: block;
    text-align: left;

    @media #{$tablet} {
      max-width: calc(100% / 3 * 2);
      height: 100%;
      padding: 2rem;
    }

    @media #{$normal} {
      padding: 6rem;
    }

    .Button {
      margin-top: 2rem;
      border: none;
    }
  }

  &__title {
    color: $color--fuscous-gray;
    margin-bottom: 1.5rem;

    @media #{$normal} {
      margin-bottom: 1.5rem;
    }
  }

  &__body {
    font-size: 2rem;
    line-height: 2.8rem;

    p {
      margin-bottom: 0;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
  }

  .overlapping-link:before {
    z-index: 1;
  }

}

.NodeCourseFull,
.GroupedContentFull,
.MemberFull,
.NodePage {
  .BlockCta__body {
    p {
      text-overflow: ellipsis;
      display: box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;

      @media #{$tablet} {
        -webkit-line-clamp: 3;
      }
    }
  }
}

.LandingPage {
  .BlockCta__body {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      display: box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 10;

      @media #{$tablet} {
        -webkit-line-clamp: 4;
      }
    }
  }
}