@import 'assets/variables';
@import 'assets/mixins';

.ParagraphUSPs {
  margin-bottom: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: white;

  @media #{$tablet} {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 6rem !important;
    padding-right: 6rem !important;
    margin-bottom: 6rem;
    margin-top: 6rem;
  }

  .LandingPage & {
    margin: 0;
    border-bottom: 2px solid $color--border;
    @media #{$tablet} {
      border-right: 2px solid $color--border;
    }
  }

  &__title {
    margin-bottom: 1.5rem;
    font-size: 2.2rem;
    @include font--secondary();
    line-height: 2.4rem;

    @media #{$normal} {
      margin-bottom: 3rem;
      font-size: 3rem;
      line-height: 3.4rem;
    }

  }

  &__body {
    margin-bottom: 2rem;
  }

  &__button {
  }

  &__usps {
    @media #{$normal} {
      width: 50%;
      display: inline-block;
    }

    &__usp {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__intro {
    margin-bottom: 3rem;
    @media #{$tablet} {
      width: 70%;
      margin-bottom: 6rem;
    }
    @media #{$normal} {
      width: 50%;
      display: inline-block;
      margin-bottom: 0;
      padding-right: 6rem;
    }
  }

  &__desktop__flex {
    @media #{$normal} {
      display: flex;
    }
  }
}
