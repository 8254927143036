@import 'assets/variables';
@import 'assets/mixins';

.ThemesPillarAllOverview {
  overflow: auto;

  > .row {
    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }
  }

  &__item-wrapper {
    .TaxonomyTermThemeTeaser__content {
      @media #{$tablet} {
        box-shadow: inset -2px 0 $color--black-squeeze;
      }
    }


    &:nth-child(3n+3) {
      .TaxonomyTermThemeTeaser__content {
        box-shadow: none
      }
    }

    &:nth-last-child(-n+3) {
      @media #{$tablet} {
        border-bottom: none;
      }
    }

    &:last-of-type {
      @media #{$mobile-only} {
        border-bottom: none;
      }
    }
  }
}
