@import 'assets/variables';
@import 'assets/mixins';

.TextBlock {
  margin-bottom: 6rem;

  p {
    margin-bottom: 3rem;
  }
}
