@import 'assets/variables';
@import 'assets/mixins';

.SearchFacets {

  .SearchFacets__more {
    @media #{$tablet-down} {
      display: none;
    }
    &__heading, &__footer {
      display: none;
    }
  }

  &__filters {
    .WebformCheckboxes__checkbox__input {
      &:hover {
        background: rgba(235, 242, 247, 0.3);
        .Searchkit__Facet__label__label {
          color: $color--steel-blue;
        }
      }

      &.icon-variant-1 {
        background: rgba(235, 242, 247, 1);

        .Searchkit__Facet__label__label {
          color: $color--steel-blue;
        }
      }
    }
  }

  @media #{$tablet-down} {
    .gradient {
      width: 100%;
      height: 5rem;
      position: fixed;

      &.top {
        top: 8.2rem;
        left: 0;
        background: linear-gradient(0deg, transparent 0%, #fff 100%);
      }

      &.bottom {
        bottom: 7.5rem;
        left: 0;
        background: linear-gradient(180deg, transparent 0%, #fff 100%);
      }
    }
  }
  .SearchContent__facet__more {
    margin-bottom: 4rem;

    .Button {
      border: 3px solid $color--calypso;

      &::before, &::after {
        display: none;
      }
    }

    .SearchContent__facet_pillar {
      padding-bottom: 2rem;
    }
  }

  &.mobile-facets-active {

    .SearchFacets__more {
      display: block;

      @media #{$tablet-down} {
        position: fixed;
        z-index: 101;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        display: block;

        .SearchFacets__more__heading {
          width: 100%;
          display: block;
          padding: 3rem 2rem 3rem 2rem;
          margin-bottom: 2rem;
          border-bottom: 2px solid $color--border;

          &__title h3{
            font-size: 2rem;
          }

          &__close {
            position: absolute;
            top: 1.7rem;
            right: 2rem;
            height: 4.4rem;
            width: 4.4rem;

            .icon {
              font-size: 2rem;
              position: relative;
              top: 0.8rem;
              left: 1.1rem;
            }
          }
        }

        .SearchFacets__filters {
          position: absolute;
          width: 100%;
          top: 8.2rem;
          padding: 2rem;
          height: 100%;
          padding-bottom: 23rem;
          overflow: scroll;
        }

        .SearchFacets__more__footer {
          background: white;
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 2rem;
        }
      }
    }
  }

  .Searchkit__Facets {

    @media #{$normal} {
      width: 25.6rem;
      left: 5rem;
      position: relative;
    }

    label {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .icon-variant-0.icon-before::before {
      background-position-x: -23em;
      background-position-y: 0em;
    }

    .icon-variant-1.icon-before::before {
      background-position-x: -22em;
      background-position-y: -1em;
    }

    .facets-label {
      font-weight: bold;
      @include font--secondary();
      text-transform: uppercase;
      font-size: 1.2rem;
    }

    .facets-heading {
      margin-bottom: 2rem;
    }

    .WebformCheckboxes__checkbox__input {
      padding: 0.5rem 1rem 0.5rem 1rem;
      margin-bottom: 1rem;

      @media #{$normal} {
        padding: 0 1rem 0 1rem;
      }

      &.disabled {
        opacity: 0.3;

        &:hover {
          background: unset;
        }
      }

      &:hover {
        text-decoration: none;
        &::before {
          background-position-y: -1em;
        }
      }
    }

    .Searchkit__Facet__RefinementCheckboxes__wrapper {
      margin-bottom: 2rem;
    }

    .Searchkit__Facet__label__label {
      @include body--small;
      @include font--medium(500);
      color: $color--fuscous-gray;
      margin-left: 0.5rem;
    }

    .Searchkit__Facet__label__count {
      float: right;
      line-height: 3.4rem;
      color: $color--steel-blue;
      font-size: 1.2rem;
    }

    input {
      display: none;
    }
  }
}
