@import 'assets/variables';
@import 'assets/mixins';

.OpinionTeaser {
  background-color: $color--black-squeeze;
  transition: background-color 0.33s linear;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  @media #{$tablet} {
    display: flex;
    flex-direction: row;
  }

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
      padding-top: 133.33%;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-end;

    @media #{$tablet} {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__imageWrapper {
    width: 8rem;
    height: 8rem;
    margin-right: 2rem;

    @media #{$tablet} {
      width: auto;
      height: 45%;
      position: absolute;
      right: 2rem;
      left: 2rem;
      bottom: 0;
      margin-right: 0;
      text-align: center;
    }

    @media #{$normal} {
      right: 6rem;
      left: 6rem;
    }

    img {
      width: auto;
      height: 100%;
    }
  }

  &__contentWrapper {
    flex: 1 1 auto;
    display: block;
    text-align: left;
    padding: 3rem 1.5rem 3rem 2rem;

    @media #{$tablet} {
      padding: 2rem;
    }

    @media #{$normal} {
      padding: 3rem;
    }
  }

  .node--sub-heading {
    font-size: 1.2rem;

    &--with-labels {
      .theme-label {
        background-color: $color--white;
        color: $color--calypso;
      }
    }
  }

  .node--sub-heading-quote {
    display: inline-block;
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color--fuscous-gray;

    span {
      text-transform: uppercase;
    }
  }

  .node--page--body {
    margin-top: 1rem;

    @media screen and (min-width: 940px) {
      -webkit-line-clamp: 3;
    }

    p {
      margin: 0;
      @include text-clamp(2);
    }

    p:not(:first-of-type) {
      display: none;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }
}
