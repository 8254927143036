@import 'assets/variables';
@import 'assets/mixins';

.StaffAllOverview {
  overflow: auto;

  > .row {
    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }
  }

  &__item-wrapper {
    border-bottom: 2px solid $color--mystic;
    @media #{$tablet} {
      border-right: 2px solid $color--mystic;
    }
  }
}
