@import 'assets/variables';
@import 'assets/mixins';

.FAQItem {
  position: relative;
  padding: 0;
  border-bottom: 2px solid $color--border;

  @media #{$tablet} {
    padding: 0;
  }

  .AccordionItem__title {
    width: 100%;
    height: 62px;
    margin-bottom: 0;
    line-height: 1.38;
    @include font--secondary();
    color: $color--steel-blue;
    display: flex;
    align-items: center;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    p {
      margin: 0;
    }
  }

  &.AccordionItem--active {
    .AccordionItem__title {
      color: $color--fuscous-gray;
    }
  }

  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    @media #{$tablet} {
      right: 2rem;
    }

    &__icon {
      position: relative;
      top: -1px;
      @include font-size(22);
      transition: transform .33s ease-in-out;
    }

    &--inactive {
      .icon-before {
        transform: rotate(90deg);

        &:before {
          background-position-y: -2em;
        }
      }
    }

    &--active {
      .icon-before {
        transform: rotate(270deg);

        &:before {
          background-position-y: 0;
        }
      }
    }
  }

  &__body {
    padding-bottom: 2rem;

    @media #{$tablet} {
      padding-bottom: 2rem;
    }

    p:last-child {
      margin: 0;
    }
  }
}
