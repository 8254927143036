@import 'assets/variables';
@import 'assets/mixins';

.Form {
  width: 100%;

  label {
    @include font-size(18);
    line-height: 2.8rem;
    margin-bottom: 1rem;
    display: block;
    font-weight: bold;
    color: $color--black;
  }

  input {
    color: $color--black;
    padding: 2rem 0 2rem 2rem;
    border: none;
    background-color: #fff;
    width: 100%;
    @include font-size(20);
    color: #4e4e4c;
    line-height: 1.4;

    &::placeholder {
      color: rgba(78,78,76, 0.6);
    }

    &:focus,
    &:hover {
      outline: 0;
      border-color: #3c3c3b;
    }
  }
}
