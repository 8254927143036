@import 'assets/variables';
@import 'assets/mixins';

.TaxonomyTermThemeTeaser {
  background-color: $color--white;
  transition: background-color 0.33s linear;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  @media #{$tablet} {
    display: flex;
    flex-direction: row;
  }

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
      padding-top: 133.33%;
    }

    @media #{$normal} {
      padding-top: 100%;
    }
  }


  &__content {
    padding: 3rem 2rem;

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    @media #{$tablet} {
      padding: 0;
      display: flex;
      flex-direction: column;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__imageWrapper {
    margin-left: 1.5rem;
    position: relative;

    @media #{$tablet} {
      width: 100%;
    }

    &:before {
      @media #{$tablet} {
        padding-top: 50%;
        display: block;
        content: '';
      }
    }

    @media #{$tablet} {
      width: 100%;
      margin-left: 0;
    }

    img {
      width: 8rem;
      height: 100%;

      @media #{$tablet} {
        width: 100%;
      }
    }
  }

  &__imageNormal {
    display: none;
    @media #{$tablet} {
      position: absolute;
      top: 50%;
      height: auto;
      display: block;
      width: 100%;
      transform: translateY(-50%);
      object-fit: cover;
    }
  }

  &__imageMobile {
    @media #{$tablet} {
      display: none !important;
    }
  }

  &__contentWrapper {
    flex: 1 1 auto;
    padding: 0;
    display: block;
    text-align: left;

    @media #{$tablet} {
      padding: 2rem;
    }

    @media #{$normal} {
      padding: 3rem;
    }
  }

  .node--page--body {
    margin-top: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    p {
      margin: 0;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }
}
