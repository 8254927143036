@import 'assets/mixins';
@import 'assets/grid';

.ThemeItem {
  color: $color--primary;

  h1, h2, h3, h4, h5 {
    color: $color--primary;
  }

  h1 {
    margin-bottom: 3rem;
  }

  p {
    color: $color--primary;
  }

  &__content {
    padding-top: 12rem;
    padding-bottom: 12rem;

    @media #{$tablet} {
      padding-top: 18rem;
      padding-bottom: 18rem;
    }

    @media #{$normal} {
      padding-top: 12rem;
      padding-bottom: 12rem;
    }
  }

  &__intro {
    margin-bottom: 3rem;
    font-size: 2rem;
    font-weight: 700;

    @media #{$tablet} {
      margin-bottom: 4rem;
    }

    @media #{$normal} {
      margin-bottom: 6rem;
    }
  }
}
