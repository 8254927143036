@import 'assets/variables';
@import 'assets/mixins';

.ArticlesHomeOverview {
  .article__teaser-container {
    padding-left: 0;
    padding-right: 0;
    border-left: 1px solid $color--border;
    border-right: 1px solid $color--border;

    &:first-child {
      border-left-color: $color--mystic;
    }

    &:last-child {
      border-right-color: $color--mystic;
    }
  }
}
