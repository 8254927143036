@import 'assets/mixins';
@import 'assets/grid';

.AnnualReport {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
