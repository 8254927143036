// Define the amount of rows.
$rows: 6;

// Define the icons from left to right in the icongrid using a human-readable
// name. This will also be part of the classname.
$icon-names: (
  home,
  search,
  hamburger,
  cross,
  caret,
  mail,
  linkedin,
  rss,
  twitter,
  facebook,
  youtube,
  asterisk,
  colon,
  download,
  phone,
  point,
  printer,
  external,
  quote,
  checkmark,
  pinned,
  info,
  checkbox-checked,
  checkbox,
  radio-checked,
  radio,
);

// The base of an icon.
@mixin icon {
  // The path here is defined starting from '/src/'.
  background-image: url('../../assets/images/icons/icongrid.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: #{length($icon-names)}em #{$rows}em;
  display: inline-block;
  font-size: 100%;
  vertical-align: middle;
  text-indent: -9999px;
  overflow: hidden;
  height: 1em;
  width: 1em;
}

// Define the following classes:
//   - icon
//   - icon-before
//   - icon-after
.icon,
.icon-before:before,
.icon-after:after {
  @include icon;
}

.icon-before:before,
.icon-after:after {
  content: '';
}

// Define classes based on the names (x-axis).
//
// Example:
//   - icon-facebook
//   - icon-twitter
@for $index from 1 through length($icon-names) {
  $name: nth($icon-names, $index);
  $offset-x: -#{$index - 1}em;
  .icon-#{$name}.icon,
  .icon--#{$name}.icon {
    background-position-x: $offset-x;
  }
  .icon-#{$name}.icon-before:before,
  .icon--#{$name}.icon-before:before {
    background-position-x: $offset-x;
  }
  .icon-#{$name}.icon-after:after,
  .icon--#{$name}.icon-after:after {
    background-position-x: $offset-x;
  }
}

// Generate variants (y-axis) for all the icons.
//
// Example:
//   - icon-variant-1 (result: 'background-position-y: -1em')
//   - icon-variant-2 (result: 'background-position-y: -2em')
@for $index from 1 through ($rows + 1) {
  $offset-y: -#{$index}em;
  .icon-variant-#{$index}.icon {
    background-position-y: $offset-y;
  }
  .icon-variant-#{$index}.icon-before:before {
    background-position-y: $offset-y;
  }
  .icon-variant-#{$index}.icon-after:after {
    background-position-y: $offset-y;
  }
}

.icon-reverse {
  transform: scale(-1);
}
