@import 'assets/mixins';
@import 'assets/grid';

.ScrollIndicator {
  border: none;
  background-color: transparent;
  display: block;
  margin: 0 auto;
  padding: 1rem 0.5rem;
  border-radius: 0.6rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media #{$tablet} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5rem;
  }

  &:active, &:focus {
    outline: none;
  }

  .Lottie {
    max-width: 1.3rem;
  }

  .Lottie > svg {
    margin: 0;
  }
}
