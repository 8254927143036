@import 'assets/variables';
@import 'assets/mixins';

.Footer {
  background-color: $color--white;
  border-top: 2px solid $color--porcelain;
  
  &__content {
    position: relative;

    &--top {
      margin-bottom: .2rem;
      padding: 6rem 2rem;

      @media #{$tablet} {
        padding: 6rem;
        padding-bottom: 0;
      }

      &:after {
        content: "";
        width: auto;
        display: block;
        position: absolute;
        bottom: -.2rem;
        left: 2rem;
        right: 2rem;
        border-bottom: .2rem solid $color--porcelain;

        @media #{$normal} {
          left: 6rem;
          right: 6rem;
        }
      }
    }

    &--bottom {
      padding: 2rem;

      @media #{$tablet} {
        padding: 2rem 6rem;
      }
    }
  }

  &__logo-container {
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    margin-bottom: 6rem;

    @media #{$normal} {
      width: 100%;
      height: auto;
      display: block;
      margin-bottom: 6rem;
    }
  }
}

.NavigationFooterMenu,
.NavigationFooterMenuBottom {
  @include font--secondary();
  display: none;
  width: 100%;
  padding: 3rem;
  flex: 1 0 auto;

  @media #{$normal} {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  .Menu {
    width: 100%;
  }

  &__item,
  &__main__item {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.icon {
      margin: 0;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      font-size: 2rem;
      margin-bottom: 2rem;
      background-position-y: -1em;

      &:hover {
        background-position-y: 0;
      }

      &+.icon {
        margin-left: 1.5rem;
      }

      a {
        display: block;
      }
    }

    &.Button-link {
      a {
        width: auto;
        display: inline-block;
        padding: 1rem 4.7rem 1rem 2.5rem;

        .NavigationFooterMenu__item__link__title {
          line-height: 1;
          font-size: 1.6rem;
          @include font--secondary();
        }

        &:hover .NavigationFooterMenu__item__link__title {
          color: #fff;
        }

        &:before,
        &:after {
          top: .7rem;
        }
      }
    }

    &__link {
      width: 100%;
      display: inline-block;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;

        >div {
          color: $color--steel-blue;
          transition: .1s color linear;
        }
      }

      &__title {
        color: $color--primary;
        font-family: $font--light;
        @include font-size(16);
        transition: color 0.333s linear;
        text-align: left;
        line-height: 2.4rem;
        transition: .1s color linear;
      }
    }
  }

  &__main__item {
    margin-bottom: 6rem;
    padding-left: 3rem;
  }
}

.NavigationFooterMenuBottom {
  &__item {
    display: inline-block;
    margin-bottom: 0;

    &+.NavigationFooterMenuBottom__item {
      margin-left: 3rem;
    }
  }
}