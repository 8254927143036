@import 'assets/variables';
@import 'assets/mixins';

.CourseInfoTeaser {
  background-color: $color--white;
  transition: background-color 0.33s linear;
  position: relative;

  @media #{$tablet} {
    height: 45rem;
  }

  @media #{$normal} {
    height: 51rem;
  }

  @media #{$tablet} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
  }

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  &:before {
    @media #{$tablet} {
      content: "";
      display: block;
      padding-top: 133.33%;
    }
  }

  &__button {
    z-index: 2;

    @media #{$mobile-only} {
      margin-bottom: 2rem;
    }

    .Button--external {
      text-align: left;
    }
  }

  .Button.action {
    text-align: left;
  }

  &__title {
    letter-spacing: 0.5px;
    margin-bottom: 1rem;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__content {

    @media #{$tablet} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

  }

  &__contentWrapper {
    flex: 1 1 auto;
    text-align: left;
    padding-right: 1.5rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__body {
      margin-bottom: 3rem;

      @media #{$tablet} {
        margin-bottom: 0;
      }

      .node--page--body {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    @media #{$normal} {
      padding: 3rem 3rem 4rem 3rem;
    }
  }

  .node--sub-heading {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .node--sub-heading-quote {
    display: inline-block;
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color--fuscous-gray;
  }

  .node--page--body {
    margin-top: 1rem;
    margin-bottom: 1rem;

    p {
      margin: 0;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }

  &__info {

    margin-bottom: 1rem;

    @media #{$tablet} {
      position: absolute;
      bottom: 10rem;
      margin-bottom: 0;
    }

    &__title {
      @include font-size(16);
      @include font--secondary();
      line-height: 1.14;
      color: $color--fuscous-gray;
    }

    &__body {
      @include font-size(16);
      font-weight: 300;
      line-height: 1.52;
      color: $color--fuscous-gray;
    }

    &__item {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}