@import 'assets/variables';
@import 'bootstrap/scss/mixins';

// -----------------------------------------------------------------------------
//
// Input styling
//
// -----------------------------------------------------------------------------


label {
  font-family: $font--secondary;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2rem;
}

.input__select, .input__tags {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: flex-start;

  @media #{$normal} {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }

  label {
    text-transform: uppercase;
    @media #{$normal} {
      white-space: nowrap;
      margin-right: 1rem;
      margin-bottom: 0;
      // margin-bottom: .5rem;
    }
  }
}

.input__tags {
  width: 100%;

  ul.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
  }
}

.SearchContent__facet_type {
  li, .WebformCheckboxes__checkbox__input {
    border: 2px solid $color--border;
    font-size: 1.2rem;

    &::before {
      display: none;
    }

    &.icon-variant-1, &:hover {
      background: $color--black-squeeze;
      border: 2px solid $color--black-squeeze;
      span {
        color: $color--steel-blue;
      }
    }
  }
}

.Filter__tags ul.tags li, .SearchContent__facet_type label{
  margin-right: 1rem;
  padding-left: 0;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border: 2px solid $color--calypso-lighter;
  color: $color--steel-blue;
  @include font--secondary();
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 0;

  &::before {
    display: none;
  }

  &.selected, &:hover {
    background: $color--white;
    border: 2px solid $color--steel-blue;
  }
}

.Select__inner__indicator {
  &:after {
    content: '>';
    position: absolute;
    right: .9rem;
    top: .9rem;
    width: 1.8rem;
    height: 1.8rem;
    padding: 0;
    display: inline-block;
    font-weight: 600;
    font-family: $font--secondary;
    color: #9AB4C9;
    line-height: 1.4rem;
    font-size: 1.8rem;
    text-align: center;
    transform: rotate(90deg);

    .Select__inner__control--menu-is-open & {
      transform: rotate(-90deg);
      color: $color--fuscous-gray;
    }
  }

  svg {
    display: none;
  }
}
