@import 'assets/variables';
@import 'assets/mixins';

.NewsRecent {
  &__item-wrapper {
    border-right: solid 2px $color--border;
    border-bottom: 2px solid $color--border;
  }

}
