@import 'assets/mixins';
@import 'assets/grid';

.Slide {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color--secondary;
    z-index: 0;
  }

  &__wrapper {
    display: flex;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;

    @media #{$normal} {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__content {
    flex: 1 1;
    position: relative;
    padding: 13.5rem 4rem 4rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: $color--elephant;

    @media #{$tablet} {
      padding-bottom: 13rem;
      height: 100vh;
    }

    @media #{$normal} {
      padding: 0 0 0 14rem;
      height: 100vh;
    }

    .ScrollIndicator {
      padding-top: 8rem;
    }
  }

  &__images {
    flex: 1 1;

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 50vw);
      height: 100%;

      @media #{$tablet} {
        grid-template-columns: repeat(2, 1fr);
      }

      &__item {
        z-index: 1;
        width: 50vw;

        @media #{$normal} {
          width: 100%;
          height: 50vh;
        }

        img {
          width: 100%;
          height: 15rem;
          object-fit: cover;

          @media #{$tablet} {
            height: 27rem;
          }

          @media #{$normal} {
            height: 100%;
          }
        }
      }
    }
  }

  h1 {
    color: $color--white;
    margin-bottom: 4rem;
  }

  p {
    font-size: 2rem;
    font-weight: 700;
    color: $color--white;

    @media #{$tablet} {
      width: 50rem;
    }
  }

  &__buttons {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .Button {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 5rem 1rem 2.5rem;
      position: relative;
      width: auto;
      height: 4rem;
      background: $color--calypso-lighter;
      border: 0;
      font-weight: 700;
      color: $color--elephant;
    }

    @media #{$tablet} {
      flex-direction: row;
    }
  }
}
