@import 'assets/variables';
@import 'assets/mixins';

.NodePage {
  min-height: 100%;
  background-color: $color--white;

  &__title {
    margin-top: 6rem;
    margin-bottom: 2rem;

    @media #{$tablet} {
      margin-top: 12rem;
      margin-bottom: 4rem;
    }
  }

  &__body {
    font-weight: bold;
    margin-bottom: 4rem;

    @media #{$tablet} {
      margin-bottom: 6rem;
    }
    
    p {
      margin-bottom: 0;
      font-family: $font--secondary;
      font-weight: 700;
    }
  }

  .node--page--paragraphs-wrapper {
    display: inline-block;
    width: 100%;
  }
}
