@import 'assets/variables';
@import 'assets/mixins';

.Video {
  margin-bottom: 3rem;

  @media #{$tablet} {
    margin-bottom: 6rem;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    width: 100%;
    position: relative;

    &:before {
      padding-top: 56.25%;
      content: '';
      display: block;
    }

    &__inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
