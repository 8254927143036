@import 'assets/variables';
@import 'assets/mixins';

.TaxonomyTermPillarTeaser {
  height: 100%;
  background-color: $color--mystic;
  transition: background-color 0.33s linear;

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  @media #{$tablet} {
    display: flex;
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 0;

    @media #{$tablet} {
      padding: 3rem 3rem 0;
    }

    @media #{$normal} {
      padding: 2rem 3rem 0;
    }
  }

  &__contentWrapper {
    display: inline;
    text-align: left;
  }

  &__heading {
    text-transform: uppercase;
    margin-bottom: 1rem;

    @media #{$tablet} {
      @include h3();
      margin-bottom: 1.5rem;
    }
  }

  &__imageWrapper {
    margin-top: auto;

    @media #{$tablet} {
      right: 3rem;
      left: 3rem;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }
}