@import 'assets/variables';
@import 'assets/mixins';

.CtaGroup {
  border-bottom: none;

  @media #{$tablet} {
    border-bottom: 2px solid $color--border;
  }

  &__item {
    border-bottom: 2px solid $color--border;

    @media #{$tablet} {
      border-right: 2px solid $color--border;
      border-bottom: none;
    }

    .CtaGroupItem {
      background-color: $color--white;
    }

    &:first-child {
      .CtaGroupItem {
        background-color: $color--kournikova;
      }
    }

    &:last-child {
      border-bottom: none;

      .CtaGroupItem {
        background-color: $color--rose-white;
      }
    }
  }
}
