@import 'assets/mixins';
@import 'assets/grid';

.Stack {
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  touch-action: none !important;

}

.StackItem {
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  &__content {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    & > div {
      flex: 1 1 auto;
    }

    position: relative;
    z-index: 1;
    transform: translateY(100%);
  }

  &:before {
    content: '';
    z-index: 0;
    //background-color: $color--dark;
    left: 0;
    right: 0;
    top: -1%;
    bottom: 0;
    position: absolute;
    transform: translateY(100%);
    transition: transform 1s cubic-bezier(.7,0,.3,1);
  }

  &.previous {
    pointer-events: none;
  }

  &.forward {
    &.previous {
      z-index: 99;

      &:before {
        transform: translateY(0%);
      }

      .StackItem__content {
        transform: translateY(0%);
      }
    }

    &.active {
      z-index: 100;

      &:before {
        transition: transform 1s cubic-bezier(.7,0,.3,1);
        transform: translateY(0%);
      }

      .StackItem__content {
        transition: transform 1s 0.1s cubic-bezier(.7,0,.3,1);
        transform: translateY(0%);
      }
    }
  }

  &.backward {
    &.active {
      z-index: 99;

      &:before {
        transform: translateY(0%);
      }

      .StackItem__content {
        transform: translateY(0%);
      }
    }

    &.previous {
      z-index: 100;

      &:before {
        transition: transform 1s 0.1s cubic-bezier(.7,0,.3,1);
        transform: translateY(100%);
      }

      .StackItem__content {
        transition: transform 1s cubic-bezier(.7,0,.3,1);
        transform: translateY(100%);
      }
    }
  }
}
