@import 'assets/variables';

*, *:before, *:after {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: auto;
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  background-color: $color--porcelain;

  &.navigation-active {
    overflow: hidden;
    @media #{$normal} {
      overflow-y: scroll;
    }
  }
}

.node--sub-heading {
  &--with-labels {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: 2rem;
    color: $color--fuscous-gray;
    text-transform: uppercase;

    @media #{$normal} {
      flex-direction: row;
    }

    .theme-label {
      flex-shrink: 0;
      margin-bottom: 1rem;
    }

    span {
      line-height: 2.5rem;
      flex-shrink: 0;
      margin-bottom: 1rem;
    }
  }
}

.theme-label {
  font-size: 1.2rem;
  background-color: $color--porcelain;
  color: $color--calypso;
  padding: 0.3rem 1rem;
  font-weight: 700;
  margin-right: 1rem;
}

.content-section {
  padding-bottom: 3rem;

  @media #{$tablet} {
    padding-top: 7rem;
    padding-bottom: 6rem;
  }

  @media #{$normal} {
    padding-top: 12rem;
  }
}

.EntityPageWrapper {
  width: 100%;
  min-height: 100vh;
  background: $color--white;
}
