@import 'assets/variables';
@import 'assets/mixins';

.FAQAccordionItem {
  .Accordion__item__title button{
    padding-right: 5rem;
  }

  &.collapsed .Accordion__item__title button{
    color: $color--steel-blue;
  }
}