@import 'assets/variables';
@import 'assets/mixins';

.ParagraphImage {
  &__modal {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 101;
    top: 0;
    left: 0;
    background: #00000063;
    padding-top: 12.5rem;
    padding-bottom: 5rem;

    @media #{$tablet} {
      padding-top: 20rem;
      padding-bottom: 20rem;
    }

    &__backdrop {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    &__inner {
      max-width: 1250px;
      margin: 0 auto;
      max-height: 100%;
      overflow: scroll;
      z-index: 100;
    }
  }

  &__image__large {
    width: 100%;
    height: auto;
  }

  &__image {
    cursor: pointer;
    width: 100%;
    height: auto;
    object-position: cover;
    margin-bottom: 1.5rem;
  }

  &__caption {
    @include font-size(12);
    line-height: 16px;
    margin-bottom: 3rem;
    letter-spacing: 0.5px;
    color: $color--fuscous-gray;

    @media #{$tablet} {
      margin-bottom: 6rem;
    }
  }
}
