@import 'assets/variables';
@import 'assets/mixins';

.InterviewTeaser {
  height: 100%;
  padding: 3rem 2rem;
  background-color: $color--black-squeeze;
  transition: background-color 0.33s linear;
  min-height: 40rem;

  @media #{$normal} {
    padding: 3rem;
  }

  &:hover,
  &:focus {
    background-color: $color--kournikova;
    transition: background-color 0.33s linear;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__theme {
    display: flex;
    text-transform: uppercase;
    font-size: 1.2rem;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin-top: 1rem;
  }

  &__body {
    margin-top: 1rem;
  }

  &__date {
    margin-top: 1rem;
    font-size: 1.4rem;

    @media #{$normal} {
      margin-top: 2rem;
    }
  }

  &__author {
    display: flex;
    align-items: center;

    @media #{$mobile-only} {
      margin-top: 1rem;
    }

    &__image {
      img {
        border: .2rem solid $color--white;
        border-radius: 100%;
        object-fit: cover;
        height: 4rem;
        width: 4rem;

        @media #{$normal} {
          height: 6rem;
          width: 6rem;
        }
      }
    }

    &__details {
      margin-left: 1rem;

      &__name {
        font-size: 1.6rem;
        font-weight: 900;
      }
    }
  }

  .theme-label {
    background-color: $color--white;
    color: $color--calypso;
  }

  .overlapping-link:before {
    z-index: 1;
  }
}