@import 'assets/variables';

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

@mixin font--primary($weight: normal, $style: normal) {
  font-family: $font--primary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--secondary($weight: normal, $style: normal) {
  font-family: $font--secondary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--tertiary($weight: normal, $style: normal) {
  font-family: $font--tertiary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--light($weight: 400, $style: normal) {
  font-family: $font--light;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--medium($weight: 400, $style: normal) {
  font-family: $font--medium;
  font-weight: $weight;
  font-style: $style;
}

@mixin font-size($size) {
  font-size: #{$size}px;
  font-size: #{$size / 10}rem;
}

@mixin h1 {
  @include font-size($font-size--h1);
  @include font--secondary();
  line-height: $line-height--h1;
  font-weight: 900;
  font-weight: bold;

  @media #{$tablet} {
    @include font-size($tablet--font-size--h1);
    line-height: $tablet--line-height--h1;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--h1);
    line-height: $desktop--line-height--h1;
  }
}

@mixin h2 {
  @include font-size($font-size--h2);
  @include font--secondary();
  line-height: $line-height--h2;
  font-weight: 900;
  font-weight: bold;
  margin-bottom: 1.5rem;

  @media #{$tablet} {
    @include font-size($tablet--font-size--h2);
    line-height: $tablet--line-height--h2;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--h2);
    line-height: $desktop--line-height--h2;
    margin-bottom: 3rem;
  }
}

@mixin h3 {
  @include font-size($font-size--h3);
  @include font--secondary();
  line-height: $line-height--h3;
  font-weight: 900;
  font-weight: bold;

  @media #{$tablet} {
    @include font-size($tablet--font-size--h3);
    line-height: $tablet--line-height--h3;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--h3);
    line-height: $desktop--line-height--h3;
  }
}

@mixin h4 {
  @include font-size($font-size--h4);
  @include font--secondary();
  line-height: $line-height--h4;
  font-weight: 900;
  font-weight: bold;

  @media #{$tablet} {
    @include font-size($tablet--font-size--h4);
    line-height: $tablet--line-height--h4;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--h4);
    line-height: $desktop--line-height--h4;
  }
}

@mixin h5 {
  @include font-size($font-size--h5);
  @include font--secondary();
  line-height: $line-height--h5;
  font-weight: 900;
  font-weight: bold;

  @media #{$tablet} {
    @include font-size($tablet--font-size--h5);
    line-height: $tablet--line-height--h5;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--h5);
    line-height: $desktop--line-height--h5;
  }
}

// Body mixins

@mixin body--regular {
  @include font-size($font-size--body);
  @include font--primary();
  line-height: $line-height--body;

  @media #{$tablet} {
    @include font-size($tablet--font-size--body);
    line-height: $tablet--line-height--body;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--body);
    line-height: $desktop--line-height--body;
  }
}

@mixin body--bold {
  @include font-size($font-size--body);
  @include font--secondary();
  line-height: $line-height--body;

  @media #{$tablet} {
    @include font-size($tablet--font-size--body);
    line-height: $tablet--line-height--body;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--body);
    line-height: $desktop--line-height--body;
  }
}

@mixin body--small {
  @include font-size($font-size--body--small);
  @include font--primary();
  line-height: $line-height--body--small;

  @media #{$tablet} {
    @include font-size($tablet--font-size--body--small);
    line-height: $tablet--line-height--body--small;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--body--small);
    line-height: $desktop--line-height--body--small;
  }
}

@mixin body--large {
  @include font-size($font-size--body--large);
  @include font--primary();
  line-height: $line-height--body--large;

  @media #{$tablet} {
    @include font-size($tablet--font-size--body--large);
    line-height: $tablet--line-height--body--large;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--body--large);
    line-height: $desktop--line-height--body--large;
  }
}

@mixin text-clamp($lineCount: 3) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
}

@mixin focus-ring($offset: 0) {
  outline: var(--focus-ring-color, $color--black) var(--focus-ring-width, 0.2rem) solid;
  outline-offset: $offset;
}

@mixin link-box-container() {
  content: '';
  position: absolute;
  inset: 0;
}

@mixin link-box($psuedo-element: 'after') {
  position: relative;

  @if ($psuedo-element == 'after') {
    a:after,
    button:after {
      @include link-box-container()
    }
  } @else {
    a:before,
    button:before {
      @include link-box-container()
    }
  }
}
