@import 'assets/variables';
@import 'assets/mixins';

.MemberFull {
  position: relative;
  overflow: hidden;
  height: 100%;

  background-color: $color--white;

  &__content {
    padding: 9rem .5rem 3rem .5rem;
    overflow: hidden;

    @media #{$tablet} {
      padding: 9rem 0 6rem 0;
    }

    @media #{$normal} {
      padding: 12rem 0 6rem 0;
    }

    h1 {
      margin-bottom: 2rem;
      letter-spacing: -0.5px;
      font-size: 28px;
      @include font--secondary();
      line-height: 1.07;

      @media #{$normal} {
        font-size: 44px;
        line-height: 1.09;
        margin-bottom: 4rem;
      }
    }
  }

  &__info {
    display: flex;
    width: 100%;
    flex-direction: column;

    @media #{$tablet} {
      flex-direction: row;
    }

    > div {
      display: flex;
      flex-direction: column;

      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      color: $color--primary;

      .icon-before {
        &:before {
          font-size: 24px;
          margin-right: 1rem;
        }
      }

      @media #{$tablet} {
        width: 33.33%;
      }

    }

    &__link {
      margin-bottom: 1.5rem;
    }

    &__since {
      margin-bottom: 2rem;

      @media #{$tablet} {
        margin-bottom: 0;
      }
    }

    &__address {
      margin-bottom: 2rem;

      @media #{$tablet} {
        margin-bottom: 0;
      }
    }

    &__address {
      &__wrapper {
        display: flex;
      }
    }

    &__mail,
    &__phone {
      margin-bottom: 1rem;
    }

    &__mail,
    &__phone {
      display: flex;
    }
  }

  .overlapping-link:before {
    z-index: 1;
  }

  &__accreditation {
    h2 {
      margin-bottom: 1.5rem;
      letter-spacing: -0.5px;
      font-size: 22px;
      @include font--secondary();
      line-height: 24px;
      letter-spacing: -0.5px;

      margin-top: 3rem;

      @media #{$tablet} {
        margin-top: 6rem;
      }

      @media #{$normal} {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 3rem;
      }
    }

    h3 {
      margin-bottom: 1.5rem;

      @media #{$tablet} {
        margin-bottom: 2rem;
      }
    }

    &__subtitle {
      @include font--primary();
      margin-bottom: 3rem !important;
      font-style: italic;
    }

    &__body {
      margin-bottom: 3rem;

    }


    &--last {
      margin-bottom: 0;
    }
  }
}
