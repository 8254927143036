@import 'assets/mixins';
@import 'assets/grid';

.SliderPage {
  flex: 1 1 auto;
  display: flex;
  background-color: $color--primary;

  & > .slick-dots {
    bottom: 2rem;
  }

  & > .slick-list {
    & > .slick-track {
      & > .slick-slide {
        position: relative;
        float: none;
        border: none;
        width: 100vw !important;
        height: 100vh !important;
        border: none;
      }
    }
  }

  &.slick-vertical > .slick-list > .slick-track {
    display: block;
  }
}

.slick-initialized .slick-slide > div {
  flex: 1 1 auto !important;
  height: 100%;
  display: flex;

  & > div {
    flex: 1 1 auto;
  }
}
