@import 'assets/variables';
@import 'assets/mixins';

.ParagraphImageAndText {

  margin-bottom: 3rem;

  @media #{$tablet} {
    margin-bottom: 6rem;
  }

  &__title {
    @include font-size(18);
    @include font--secondary();
    line-height: 1.11;
    letter-spacing: -0.5px;
    color: $color--fuscous-gray;
    margin-bottom: 1.5rem;

    @media #{$normal} {
      @include font-size(20);
      line-height: 24px;
      margin-bottom: 2rem;
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 3rem;
  }

  &__body {
    margin-bottom: 2rem;
  }

}
